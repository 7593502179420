import { useNavigate } from "hooks/useNavigate";
import { Button, Flex, Tag, Text } from "@chakra-ui/react";
import { components } from "api/typesgen";
import { path } from 'ramda';
import { DataTable } from "components/DataTable/DataTable";
import { EditIcon, DeleteIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { ButtonDanger } from "components/ButtonDanger/ButtonDanger";
import { Column } from "react-table";
import { DataTableSkeleton } from "components/DataTable/Skeleton";
import InputTextFilter from "components/DataTable/Filters/InputTextFilter";
import SelectFilter from "components/DataTable/Filters/SelectFilter";
import RightSide from "modules/client/ClientTabs";

const CustomDateCell = ({ value }) => {
  const date = new Date(value); 
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'long' });
  const displayText = `${month} ${year}`;

  return (
    <Tag variant='solid' colorScheme='blue' textTransform='capitalize'>
      {displayText}
    </Tag>
  );
};
const columns: Column<components['schemas']['Goal']>[] = [
    
    {
        id: 'ClientId',
        Header: "Client",
        accessor: path(['Client', 'name']),
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Client'}} />,
        filter: "rankedMatchSorter",
    },    
    {
        id: 'GoalDate',
        Header: "Date/Month",
        accessor: "goalDate",
        Cell:CustomDateCell,
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Goal Date'}} />,
        filter: "rankedMatchSorter",
    },    
    {
        id: 'GoalTypeId',
        Header: "Type",
        accessor: path(['GoalType','description']),
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Goal Type'}} />,
        filter: "rankedMatchSorter",
    },
    {
        id: 'GoalSales',
        Header: "Sales Goal",
        accessor: "goalSales",
        Cell:({ value }) =>  <div style={{ textAlign: 'center' }}>{value ? value : value}</div>,
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Goal Sales'}} />,
        filter: "rankedMatchSorter",
    },
    {
        id: 'GoalLeads',
        Header: "Lead Goal",
        accessor: "goalLeads",
        Cell:({ value }) =>  <div style={{ textAlign: 'center' }}>{value ? value : value}</div>,
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Goal Leads'}} />,
        filter: "rankedMatchSorter",
    },
    {
        id: 'desiredClosingRatio',
        Header: "Desired closing Ratio%",
        accessor: "desiredClosingRelation",        
        Cell:({ value }) =>  <div style={{ textAlign: 'center' }}><Tag >{value ?value : value} %</Tag></div>,
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Desired Closing Ratio'}} />,
        filter: "rankedMatchSorter",
    },    
    {
        id: 'Status',
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <Tag variant='solid' colorScheme={value ? 'green' : 'yellow'} textTransform='uppercase'>{value ? 'active' : 'inactive'}</Tag>,
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Status'}} />,
        filter: "rankedMatchSorter",
    }
];


export type GoalListDatatableProps = {
    data: components['schemas']['Goal'][];
    deleteVendor: (id: string) => void;
    id?: string;
}


export function GoalsListDataTable({ data, deleteVendor}: GoalListDatatableProps){
    const navigate = useNavigate();
    const tableHeaderMarkup = (
        <div className='datatable-header'>
            <div className='datatable-header-title'>
                <Text fontSize={'lg'} fontWeight={'bold'}>
                GOALS
                </Text>
          </div>
        
            <div className="datatable-header-action">
                <Button
                    rightIcon={<ChevronRightIcon height={6} width="auto" color="cyan" />}
                    variant="mojoDefault"
                    onClick={() => navigate('/goals/new')}
                >ADD GOAL</Button>
            </div>
        </div>
    );
    const tableMarkup = 
        <DataTable
            data={data}
            columns={columns}
            chooseRows
            showFilter
        >
            {{
                rowFooter: (row: any) => (
                    <Flex gap={1}>
                        <Button variant="mojoPrimaryGhost" onClick={() => data && navigate(`/goals/${row?.GoalId}`)}><EditIcon /></Button>
                        <ButtonDanger onClick={() => data && deleteVendor(row?.GoalId)}><DeleteIcon /></ButtonDanger>
                    </Flex>
                )
            }}
        </DataTable>

    return (
        <>
            <div className="datatable-main datatable-controls datatable-client-list">
                {tableHeaderMarkup}
                {tableMarkup}
            </div>
        </>
    );
}