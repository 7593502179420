import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, useToast, Box } from "@chakra-ui/react";
import { components } from "api/typesgen";
import { Column } from "react-table";
import { DataTableSkeleton } from "components/DataTable/Skeleton";
import { useOutletContext, useParams } from "react-router";
import { path } from "ramda";
import { InlineEditableDataTable } from "components/DataTable/InlineEditableDataTable";
import { useMojoFetch } from "api/useMojoFetch";
import { useMojoEffect } from "api/useMojoEffect";
import { ArrayElement } from "lib/types/utils";
import { useState, useCallback, useEffect } from "react";
import InputText from "../../../components/InputText";
import { Select, useSelect } from "../../../components/Select/Select";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { ScrollableInlineEditableDataTable } from "../../../components/DataTable/ScrollableInlineEditableDataTable";

const EditableNumberCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateRowData
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback(value => {
      setValue(value);
      if (updateRowData) {
          updateRowData(index, id, +value);
      }
  }, [index, id, updateRowData]);

  useEffect(() => {
      setValue(initialValue);
  }, [initialValue]);

  return (
      <NumberInput value={value} onChange={onChange} width={"100px"} step={0.01} >
          <NumberInputField  sx={{fontSize: '13px'}}/>
      </NumberInput>
  );
}

const UnEditableNumberCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateRowData
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback(value => {
      setValue(value);
      if (updateRowData) {
          updateRowData(index, id, +value);
      }
  }, [index, id, updateRowData]);

  useEffect(() => {
      setValue(initialValue);
  }, [initialValue]);

  return (
      <NumberInput value={value} width={"100px"} isReadOnly={true}>
          <NumberInputField sx={{fontSize: '13px'}}/>
      </NumberInput>
  );
}

const IDS = {
  department: {
      new: '1acdd068-7d8c-4398-9e0f-2097b22fbf8c',
      used: '75cfbc6b-ae71-4443-9cb7-a6443fbdca90',
      service: 'c41f6f0d-8ce3-48a3-a7b9-a7bc5a1a9928',
    },
    paymentType: {
      invoice: 'f22c4cbf-4021-4c39-8004-d49d1a7bfcd8',
      creditCard: 'cdb28eb1-3c2b-42e6-9b82-d0bc4145ff98',
    },
    service: {
      thirdParty: 'c026f441-44fc-4353-9000-f75b6631c514',
      paidMedia: '9c857b28-7891-4a95-ad7c-89df8163b93c',
      social: '486414c5-30a0-4811-985b-a5d32c0d42e4',
    },
}

export function BudgetChannelDetail() {
  // const { channelTypeId } = useParams();
  const channelTypeId = "b52ce128-144c-4962-ac19-ae9ae68eeb5f";
  const { budget, channelBudgets, refetch, hideForm } = useOutletContext<{
    channelBudgets: components['schemas']['ChannelBudget'][],
    budget: components['schemas']['Budget'],
    refetch: () => void,
    hideForm: boolean,
   }>();
  const toast = useToast();
  const [searchText, setSearchText] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedDept, setSelectedDept] = useState("");
  const [selectedBudgetId, setSelectedBudgetId] = useState("");

  const { data: tenantsData } = useMojoFetch('/api/v1/Clients/', 'get');
  const { data: vendorsData } = useMojoFetch('/api/v1/Vendors/all', 'get');
  const { data: servicesData } = useMojoFetch('/api/v1/Services/all', 'get');
  const { run: runDelete } = useMojoEffect(`/api/v1/ChannelBudgets/${selectedBudgetId}`, 'delete');
  const { run: runUpdate } = useMojoEffect(`/api/v1/ChannelBudgets/${selectedBudgetId}`, 'patch');
  const {data: vendorTypesData, isLoading: isVendorTypesDataLoading} = useMojoFetch("/api/v1/VendorsTypes/all", "get");


  const deleteChannelBudget = async (channelBudget: ArrayElement<typeof channelBudgets>) => {
    setSelectedBudgetId(channelBudget.ChannelBudgetId );
    const [deleteData, error] = await runDelete();
    if (error === null) {
      toast({
          title: 'Channel Budget deleted',
          status: 'success',
      });
      await refetch();
    } else {
      toast({
          title: 'Failed to delete Channel Budget',
          status: 'error',
      })
    }
  }

  const updateChannelBudget = async (idx: number, channelBudget) => {
    if (!channelBudgets) {
      return;
    }
    setSelectedBudgetId(channelBudgets[idx].ChannelBudgetId );
    const [updateData, error] = await runUpdate(channelBudget);
    if (error === null) {
      toast({
          title: 'Channel Budget updated',
          status: 'success',
      });
      await refetch();
    } else {
      toast({
          title: 'Failed to update Channel Budget',
          status: 'error',
      })
    }
  }

  const {tenantIDs}=useAppSelector((state:RootState)=>state.user);
  
  const departmentsData = [
    { DepartmentId: IDS.department.new, name: 'New' },
    { DepartmentId: IDS.department.service, name: 'Service' },
    { DepartmentId: IDS.department.used, name: 'Used' },
];

  
  const tenantOptions = tenantsData ?
  tenantsData.filter((e)=>{return tenantIDs.indexOf(e.TenantId)!==-1?true:false}).map(({ TenantId, name }) =>({label:name,value:TenantId})) :
  [];

const departmentOptions = departmentsData ?
  departmentsData.map(({ DepartmentId, name }) => ({ label: name, value: DepartmentId })) :
  [];
const serviceOptions = servicesData ?
  servicesData.map(({ ServiceId, name }) => ({ label: name, value: ServiceId })) :
  [];
const vendorOptions = vendorsData ?
  vendorsData.map(({ VendorId, name }) => ({ label: name, value: VendorId })) :
  [];

  const currentYear = new Date().getFullYear();
const yearOptions = Array.from({ length: 11 }, (_, index) => {
  const year = currentYear - index;
  return { label: year.toString(), value: year.toString() };
});

  const year = budget?.year ? budget.year : new Date().getFullYear();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const months = Array.from({ length: 12 }, (_, month) => ({
    id: `month${month + 1}`,
    Header: `${monthNames[month]}`, //`${month + 1}/${year}`,
    accessor: `month${month + 1}`,
  } as Column<components['schemas']['ChannelBudget']>))
  
  
  const budgetDetails = [{
    id: `service`,
    Header: "Service",
    accessor: `servicex`,
  } as Column<components['schemas']['ChannelBudget']>,{
    id: `vendor`,
    Header: "Vendor",
    accessor: `vendorx`,
  } as Column<components['schemas']['ChannelBudget']>,{
    id: `department`,
    Header: "Department",
    accessor: `departmentx`,
  } as Column<components['schemas']['ChannelBudget']>,{
    id: `type`,
    Header: "Type",
    accessor: `typex`,
  } as Column<components['schemas']['ChannelBudget']>]

  const columns: Column<components['schemas']['ChannelBudget']>[] = [
    ...budgetDetails,
    ...months,
    {
      id: "ytd",
      Header: "YTD",
      accessor: `ytd`,
    },
    {
      id: "avg",
      Header: "AVG",
      accessor: `avg`,       
    },
  ] as any;

  const filteredData = channelBudgets?.filter(cb => cb.ChannelTypeId === channelTypeId); 
  const filterDataWithAverages = (filteredData && budget) ? filteredData.map((df:any, i:number)=>{
    const ytd = (df.month1 + df.month2 + df.month3 + df.month4 + df.month5 + df.month6 + df.month7 + df.month8 + df.month9 + df.month10 + df.month11 + df.month12);
    df.ytd = "$" + ytd.toFixed(2).toString();
    df.avg = "$" + (ytd / 12).toFixed(2).toString();
    df.servicex = serviceOptions.find(x => x.value ===  budget.ServiceId)?.label;
    df.vendorx = vendorOptions.find(x => x.value ===  budget.VendorId)?.label;
    df.departmentx = departmentOptions.find(x => x.value ===  budget.DepartmentId)?.label;
    df.typex = ''//budget.type;
    if(df.typex.length >= 1){
      df.typex = df.typex.toString().slice(0, 1).toUpperCase() + df.typex.toString().slice(1);
    }
    return df;
  }) : filteredData;

 

  const tableMarkup = filteredData ? (
    <InlineEditableDataTable
        data={filterDataWithAverages}
        columns={columns}
        updateRow={updateChannelBudget}
        deleteRow={deleteChannelBudget}
        cells={{
          EditableNumberCell,
          UnEditableNumberCell,
        }}
        
        cellTypes={{
          month1: 'EditableNumberCell',
          month2: 'EditableNumberCell',
          month3: 'EditableNumberCell',
          month4: 'EditableNumberCell',
          month5: 'EditableNumberCell',
          month6: 'EditableNumberCell',
          month7: 'EditableNumberCell',
          month8: 'EditableNumberCell',
          month9: 'EditableNumberCell',
          month10: 'EditableNumberCell',
          month11: 'EditableNumberCell',
          month12: 'EditableNumberCell',
          ytd: 'UnEditableNumberCell',
          avg: 'UnEditableNumberCell',
          }}
    />
  ) : <DataTableSkeleton columns={columns}>
          {{ rowFooter: () => <></> }}
      </DataTableSkeleton>;


  return (
      <>
         <Box>
        <Box style={{ display:'inline-block', marginRight:'8px' }} width="280px">
          <InputText value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search" />
        </Box>
        <Box style={{ display:'inline-block', marginRight:'8px' }} width="150px">
          <Select
            placeholder="Year"
            onChange={(e: any) => setSelectedYear(e)}
            options={yearOptions}
            defaultValue={""}
            value={selectedYear}
          />
        </Box>
        <Box style={{ display:'inline-block', marginRight:'8px' }} width="280px">
          <Select
            placeholder="Service"
            onChange={(e: any) => setSelectedService(e)}
            options={serviceOptions}
            defaultValue={""}
            value={selectedService}
          />
        </Box>
        <Box style={{ display:'inline-block', marginRight:'8px' }} width="280px">
          <Select
            placeholder="Vendor"
            onChange={(e: any) => setSelectedVendor(e)}
            options={vendorOptions}
            defaultValue={""}
            value={selectedVendor}
          />
        </Box>
        <Box style={{ display:'inline-block', marginRight:'8px' }} width="280px">
          <Select
            placeholder="Dept"
            onChange={(e: any) => setSelectedDept(e)}
            options={departmentOptions}
            defaultValue={""}
            value={selectedDept}
          />
        </Box>
      </Box>
      <Box style={{ overflowX: 'auto', width: !hideForm ? '82em' : '100%' }}>
        <Box className="datatable-main datatable-controls datatable-client-list">
          {tableMarkup}
        </Box>
      </Box>
 
      </>
  );
}