import { useParams } from "react-router-dom";
import { useMojoEffect } from "api/useMojoEffect";
import { useMojoFetch } from "api/useMojoFetch";
import { useMemo, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { VendorForm, VendorFormProps } from "../VendorForm";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { useNavigate } from "hooks/useNavigate";
import { ar } from "date-fns/locale";


function VendorEditForm({ partnerId }){
    const id = partnerId;
    const [vendorId, setVendorId] = useState('');
    const { run: runUpdate } = useMojoEffect(`/api/v1/Vendors/${vendorId}`, 'patch');

    const {
        data: vendorData,
        error: vendorError,
        isLoading,
    } = useMojoFetch(`/api/v1/Vendors/${partnerId}`, 'get');

    const toast = useToast();
    
    const values = vendorData as VendorFormProps['values'];
   
    return (
        <VendorForm
            isLoading={false}
            //isSubmitting={isRunningUpdate}
            submit={async (args) => {
                console.log('update', args);
                const updateVendorData = {
                    name: args.name,
                    VendorTypeId: args.VendorTypeId,
                    presenter: args.presenter,
                    reviewDate: args.reviewDate,
                    companyLaunch: args.companyLaunch,
                    website: args.website,
                    acctsPerRep: args.acctsPerRep,
                    terms: args.terms,
                    creative: args.creative,
                    customerService: args.customerService,
                    planning: args.planning,
                    reporting: args.reporting,
                    execution: args.execution,
                    notes: args.notes,
                    numberOfClients: args.numberOfClients,
                    numberOfEmployees: args.numberOfEmployees,
                    RevieweeId: args.RevieweeId,
                    VendorRecommendId: args.VendorRecommendId,
                    id,
                }
                setVendorId(id);
                const [updatedData, error] = await runUpdate(updateVendorData);
                if (error === null) {
                    toast({
                        title: 'Partner updated',
                        status: 'success',
                    });
                } else {
                    
                    toast({
                        title: 'Partner update failed',
                        status: 'error',
                    });
                }
                return updatedData;
            }}
            values={values}
            defaultValues={values}
            btnTitle="UPDATE PARTNER"
            btnIcon={<EditIcon />}
            formTitle={`UPDATE ${vendorData?.name}`}   
            />
    )
}
function VendorAddForm({ onlyRequired }: { onlyRequired?: boolean }){
    const { run: runCreate } = useMojoEffect('/api/v1/Vendors/create', 'post');
    
    const toast = useToast();
    return (
        <VendorForm
            isLoading={false}
            //isSubmitting={isRunningCreate}
            submit={async (args) => {
                console.log('create: ', args);
                const [createdData, error] = await runCreate({
                    ...args,
                    numberOfClients: Number(args.numberOfClients),
                    numberOfEmployees: Number(args.numberOfEmployees),
                    acctsPerRep: Number(args.acctsPerRep),
                });
                if (error === null) {
                    toast({
                        title: 'Partner created',
                        status: 'success',
                    });
                } else {
                    
                    toast({
                        title: 'Partner create failed',
                        status: 'error',
                    });
                }
                return createdData;
            }}
            btnTitle="ADD PARTNER"
            btnIcon={<EditIcon />}
            formTitle={`CREATE NEW PARTNER`}   
            />
    )
}

export function AddEditVendorsForm({ onlyRequired }: { onlyRequired?: boolean }){
    const { partnerId } = useParams();
    const form = useMemo(() => {
        if (partnerId) {           
            return (               
               <VendorEditForm partnerId={partnerId} />
            );
        }
        return (
            <VendorAddForm onlyRequired={onlyRequired} />
        );
    }, [partnerId, onlyRequired]);
    return form;
}