import { Image } from "@chakra-ui/react";
import {
    useColorModeValue,
    HStack,
    Flex,
    Select,
    Box,
} from "@chakra-ui/react";
import logo from "logo-teal.png";
import UserMenu from "components/UserMenu";
import NotificationsButton from "components/NotificationsButton";
import "./topbar.scss";
import { useMojoFetch } from "api/useMojoFetch";
import { useEffect, useState } from "react";


export function TopBar() {

    const bg = useColorModeValue("white.100", "mojo.500");
    const [groupOptions, setGroupOptions] = useState<Array<any>>([]);
    const { data: groupsData } = useMojoFetch("/api/v1/groups/", "get");
    const [selectedGroup, setSelectedGroup] = useState("");

    useEffect(() => {
        if (groupsData) {setGroupOptions([{ label: "All", value: "" },
        ...groupsData?.map(({ id, name }) => ({
        label: name,
        value: id,
        })),
        ]);
        }
    }, [groupsData]);

    const handleChange = (event) => {
        setSelectedGroup(event.target.value); 
        console.log("selected group:", selectedGroup)
    };


    return (
        <Box className="mojo-top-bar" bg={bg}>
            <Flex className="mojo-top-bar-content">
                <HStack className="mojo-top-bar-left" spacing={10}>
                    <Box className="mojo-logo-wrapper">
                        <Image className="branding-logo" p={2} src={logo} alt="Mojo logo" />
                    </Box>
                </HStack>
                <HStack className="mojo-top-bar-right" spacing={10}>
                    <Select className="organization-select"
                        bg={bg}
                        placeholder="Organization"
                        onChange={handleChange} 
                    >
                        {groupOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select> 
                    <Flex className="top-bar-right-actions">
                        <NotificationsButton />
                        <UserMenu />
                    </Flex>
                </HStack>
            </Flex>
        </Box>
    );
}
