import {useToast, Checkbox, Card, CardBody, Icon, Link} from "@chakra-ui/react";
import {AddEditPresentationsForm} from "../AddEditPresentationsForm";
import {useParams} from "react-router-dom";
import {useMojoFetch} from "api/useMojoFetch";
import {useMojoEffect} from "api/useMojoEffect";
import {ArrayElement} from "lib/types/utils";
import "./Presentations.scss";
import IconAlert from "snippets/icon-alert";
import {CopyIcon, DeleteIcon, EditIcon, ViewIcon} from "@chakra-ui/icons";

function VendorPresentations() {
	const {vendorId} = useParams();
	console.log("vendorId: ", vendorId);
	const {error, data, refetch} = useMojoFetch(
		`/api/v1/VendorFiles/all/${vendorId}`,
		"get"		
	);

	return (
		<>
			<AddEditPresentationsForm vendorId={vendorId || ""} refetch={refetch} />
			<h5>FILES</h5>
			<div className="presentations-files-list">
				{data?.urls?.map((x, i) => (
					<Card direction="row" overflow="hidden" variant="elevated" size="sm">
						<CardBody>
							<div className="items-left">
								<CopyIcon />
								<Link href={Object.values(x)[0] as string} isExternal size="sm">
									{Object.keys(x)[0]}
								</Link>
							</div>
							<div className="icons-right">
								<ViewIcon />
								<DeleteIcon />
							</div>
						</CardBody>
					</Card>
				))}
			</div>
		</>
	);
}

export default VendorPresentations;
