import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button, Heading, Box, Flex } from '@chakra-ui/react';
import { useNavigate } from 'hooks/useNavigate';
import './BudgetsUpload.scss';
import { Uploader } from 'components/Uploader/Uploader';

const file_header = [
  'Location',
  'Objective',
  'Type',
  'Audience',
  'Department',
  'Channel',
  'Medium',
  'Partner',
  'Notes',
  'COOP Eligible',
  'COOP Percentage',
  'Year',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
  'Avg',
  'Total',
];

const columns: any[] = [
  {
    title: 'Location',
    dataIndex: file_header[0],
    key: 'location',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Location === null || b.Location === null) return 0;
      return (a.Location ?? '').localeCompare(b.Location ?? '');
    },
  },
  {
    title: 'Objective',
    dataIndex: file_header[1],
    key: 'objective',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Objective === null || b.Objective === null) return 0;
      return (a.Objective ?? '').localeCompare(b.Objective ?? '');
    },
  },
  {
    title: 'Type',
    dataIndex: file_header[2],
    key: 'type',
    fixed: 'left',
    width: 100,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Type === null || b.Type === null) return 0;
      return (a.Type ?? '').localeCompare(b.Type ?? '');
    },
  },
  {
    title: 'Audience',
    dataIndex: file_header[3],
    key: 'audience',
    fixed: 'left',
    width: 125,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Audience === null || b.Audience === null) return 0;
      return (a.Audience ?? '').localeCompare(b.Audience ?? '');
    },
  },
  {
    title: 'Dept',
    dataIndex: file_header[4],
    key: 'department',
    fixed: 'left',
    width: 100,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Department === null || b.Department === null) return 0;
      return (a.Department ?? '').localeCompare(b.Department ?? '');
    },
  },
  {
    title: 'Channel',
    dataIndex: file_header[5],
    key: 'channel',
    fixed: 'left',
    width: 125,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Channel === null || b.Channel === null) return 0;
      return (a.Channel ?? '').localeCompare(b.Channel ?? '');
    },
  },
  {
    title: 'Medium',
    dataIndex: file_header[6],
    key: 'medium',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Medium === null || b.Medium === null) return 0;
      return (a.Medium ?? '').localeCompare(b.Medium ?? '');
    },
  },
  {
    title: 'Partner',
    dataIndex: file_header[7],
    key: 'partner',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Partner === null || b.Partner === null) return 0;
      return (a.Partner ?? '').localeCompare(b.Partner ?? '');
    },
  },
  {
    title: 'Notes',
    dataIndex: file_header[8],
    key: 'notes',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Notes === null || b.Notes === null) return 0;
      return (a.Notes ?? '').localeCompare(b.Notes ?? '');
    },
  },
  {
    title: 'COOP Eligible',
    dataIndex: file_header[9],
    key: 'coop_eligible',
    fixed: 'left',
    width: 125,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a['COOP Eligible'] === null || b['COOP Eligible'] === null) return 0;
      return (a['COOP Eligible'] ?? '').localeCompare(b['COOP Eligible'] ?? '');
    },
  },
  {
    title: 'COOP (%)',
    dataIndex: file_header[10],
    key: 'coop_percentage',
    fixed: 'left',
    width: 100,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a['COOP Percentage'] === null || b['COOP Percentage'] === null)
        return 0;
      return (a['COOP Percentage'] ?? 0) - (b['COOP Percentage'] ?? 0);
    },
  },
  {
    title: 'Year',
    dataIndex: file_header[11],
    key: 'year',
    width: 100,
  },
  {
    title: 'Jan',
    dataIndex: file_header[12],
    key: 'january',
    width: 100,
  },
  {
    title: 'Feb',
    dataIndex: file_header[13],
    key: 'february',
    width: 100,
  },
  {
    title: 'Mar',
    dataIndex: file_header[14],
    key: 'march',
    width: 100,
  },
  {
    title: 'Apr',
    dataIndex: file_header[15],
    key: 'april',
    width: 100,
  },
  {
    title: 'May',
    dataIndex: file_header[16],
    key: 'may',
    width: 100,
  },
  {
    title: 'June',
    dataIndex: file_header[17],
    key: 'june',
    width: 100,
  },
  {
    title: 'July',
    dataIndex: file_header[18],
    key: 'july',
    width: 100,
  },
  {
    title: 'Aug',
    dataIndex: file_header[19],
    key: 'august',
    width: 100,
  },
  {
    title: 'Sept',
    dataIndex: file_header[20],
    key: 'september',
    width: 100,
  },
  {
    title: 'Oct',
    dataIndex: file_header[21],
    key: 'october',
    width: 100,
  },
  {
    title: 'Nov',
    dataIndex: file_header[22],
    key: 'november',
    width: 100,
  },
  {
    title: 'Dec',
    dataIndex: file_header[23],
    key: 'december',
    width: 100,
  },
  {
    title: 'Average',
    dataIndex: file_header[24],
    key: 'average',
    width: 100,
  },
  {
    title: 'Total',
    dataIndex: file_header[25],
    key: 'total',
    width: 100,
  },
  // {
  //     title: ' ',
  //     key: 'controls',
  //     fixed: 'right',
  //     render: () => <span className="table-controls">Edit</span>
  // }
];

export function BudgetsUploadForm() {
  const navigate = useNavigate();

  return (
    <>
      <Box className='budget-upload--form'>
        <Flex className='budget-upload--header'>
          <Button
            className='budget-upload--back-btn'
            leftIcon={<ChevronLeftIcon height={6} width='auto' color='cyan' />}
            variant='mojoDefault'
            onClick={() => navigate('/budgets')}
          >
            BUDGETS
          </Button>
          <Heading className='budget-upload--heading'>
            UPLOAD BUDGET FILES
          </Heading>
        </Flex>
        <Uploader
                  file_header={file_header}
                  columns={columns}
                  maxFiles={4}
                  file_type={'budgets'}
                  verification_url={'/api/v1/clients/byname/'}
        />
      </Box>
    </>
  );
}
