import {Outlet, useParams, useLocation} from "react-router-dom";
import {EditorWithDetails} from "layout/EditorWithDetails";
//import { Tabs } from "./Tabs";
import {AddEditVendorsForm} from "../AddEditVendorsForm";
import {TabItemEnum} from "../VendorsTabs/TabItemEnum";
import Tabs from "../VendorsTabs/Tabs";
import {Button, Heading} from "@chakra-ui/react";
import {ChevronLeftIcon} from "@chakra-ui/icons";
import {useNavigate} from "hooks/useNavigate";

import "./VendorDetail.scss";
import {useMojoFetch} from "api/useMojoFetch";
import { useState } from "react";

function toTabItem(value: string): TabItemEnum | null {
	const enumValues = Object.values(TabItemEnum);
	if (enumValues.includes(value as TabItemEnum)) {
		return value as TabItemEnum;
	}
	return null;
}

export function VendorDetail() {
	const [hideForm, setHideForm] = useState(false);
	//const {partnerId} = useParams();
	const navigate = useNavigate();
	/*
	const {data: vendor} = useMojoFetch("/api/v1/Vendors/", "get", {
		id: partnerId ?? "",
	});
*/
	const {pathname} = useLocation();

	const lastPart = pathname.split("/").at(-1);
	const currentTab = (lastPart && toTabItem(lastPart)) || TabItemEnum.Contacts;

	return (
		<>
			<EditorWithDetails>
				<div className="client-form-header">
					<Button
						leftIcon={<ChevronLeftIcon height={6} width="auto" color="cyan" />}
						variant="mojoDefault"
						onClick={() => navigate("/partners")}
					>
						PARTNERS LIST
					</Button>
					{!hideForm && (
						<Heading size={"md"} style={{marginRight: "auto"}}>
							ADD NEW PARTNER
						</Heading>
					)}
					<div
						className="collapse-icon"
						role="button"
						onClick={() => setHideForm(!hideForm)}
					>
						<img
							src={`/pages/clients/list/${
								hideForm ? "open" : "close"
							}-arrows.svg`}
							alt =''
						/>
					</div>
					{hideForm && <Tabs currentTab={currentTab} />}
				</div>
				{hideForm ? <></> : <Tabs currentTab={currentTab} />}
			</EditorWithDetails>
			<EditorWithDetails hideLeftPanel={hideForm}>
				<AddEditVendorsForm />
				<Outlet />
			</EditorWithDetails>
		</>
	);
}
