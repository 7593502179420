import { Column } from "react-table";
import { InlineEditableDataTable } from "components/DataTable/InlineEditableDataTable";
import { DataTableSkeleton } from "components/DataTable/Skeleton";
import "./datatables.scss";
import {
  allIntegrationStatus,
  TenantIntegrationData,
} from "data/allIntegrations";
import { Tag } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";

const columns: (Column<TenantIntegrationData> & {
  label?: (contact: TenantIntegrationData) => string;
  masked?: boolean;
})[] = [
  {
    Header: "INTEGRATION NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
    Cell: ({ value }) => (
      <Tag
        variant="solid"
        colorScheme={value === allIntegrationStatus.ACTIVE ? "green" : "red"}
        textTransform="uppercase"
      >
        {value}
      </Tag>
    ),
  },
];

const tableHeader = <></>;

export type IntegrationsDataTableProps<T = TenantIntegrationData> = {
  isLoading: boolean;
  data: T[];
  tenantId: string;
  refetch: () => void;
};

export function IntegrationsDataTable({
  data,
  isLoading,
  tenantId,
  refetch
}: IntegrationsDataTableProps) {
  const { getAccessTokenSilently } = useAuth0();

  const deleteRow = async (row: TenantIntegrationData) => {
   let token = await getAccessTokenSilently();

    const res = await fetch(
      `${
        process.env.REACT_APP_API_URL
      }/api/v1/TenantIntegrations/${row.id.toLowerCase()}/${tenantId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    console.log({
      res,
    });
    await refetch();
  };
  const tableMarkup = !isLoading ? (
    <InlineEditableDataTable
      deleteRow={deleteRow}
      data={data}
      columns={columns}
      onlyDelete
    >
      {{ tableHeader }}
    </InlineEditableDataTable>
  ) : (
    <DataTableSkeleton columns={columns}>
      {{ rowFooter: () => <></> }}
    </DataTableSkeleton>
  );

  return (
    <div className="datatable datatable-integration-list">
      <div className="datatable-controls screen">{tableMarkup}</div>
    </div>
  );
}
