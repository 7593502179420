import { useToast } from "@chakra-ui/react";
import { ContactsDataTable } from "../ContactsDataTable";
import AddEditContactForm from "../AddEditContactForm";
import { useMojoFetch } from "api/useMojoFetch";
import { useParams } from "react-router-dom";
import { useMojoEffect } from "api/useMojoEffect";
import { ArrayElement } from "lib/types/utils";

function VendorContacts() {
    const { vendorId } = useParams();
    
    const { error, data, refetch } = useMojoFetch(
        `/api/v1/VendorContacts/all/${vendorId}`,
        "get"        
    );
    const { run: runDelete } = useMojoEffect(
        "/api/v1/VendorContacts/",
        "delete"
    );
    const { run: runUpdate } = useMojoEffect(
        "/api/v1/VendorContacts/",
        "patch"
    );
    
    const toast = useToast();

    const deleteContact = async (contact) => {
         const [body, error] = await runDelete(contact.VendorContactId);
        if (error === null) {
            toast({
                title: "Contact deleted",
                status: "success",
            });
           await refetch();
        } else {
            toast({
                title: "Failed to delete contact",
                status: "error",
            });
        }
    };

    const updateContact = async (
        idx: number,
        contact: any
    ) => {
        if (!data) {
            return;
        }
        const fullContact = {
            ...data[idx],
            ...contact,
        };
        console.log("updating contact", { fullContact });
        const [body, error] = await runUpdate({
            ...fullContact,
            id: `${fullContact.VendorContactId}`,
        });
        if (error === null) {
            toast({
                title: "Contact updated",
                status: "success",
            });
            await refetch();
        } else {
            toast({
                title: "Failed to update contact",
                status: "error",
            });
        }
    };
    var myData:any;

    if (vendorId === undefined) {
        myData = [];
    }
    else {
        myData = data;
    }

    return (
        
        <>
            <AddEditContactForm vendorId={vendorId || ""} refetch={refetch} />
            <ContactsDataTable
                data={myData}
                error={error}
                deleteContact={deleteContact}
                updateContact={updateContact}
            />
        </>
    );
}

export default VendorContacts;
