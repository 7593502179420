import { IntegrationsDataTable } from "../IntegrationsDataTable";
import { AddEditIntegrationForm } from "../AddEditIntegrationForm";
import { useParams } from "react-router-dom";
import { useMojoFetch } from "api/useMojoFetch";
import { useEffect, useState } from "react";
import {
  allIntegrationStatus,
  allTenantIntegrations,
  TenantIntegrationData,
} from "data/allIntegrations";

function TenantIntegrations() {
  const [data, setData] = useState<TenantIntegrationData[]>([]);
  const { tenantId } = useParams();

  const {
    data: integrationData,
    isLoading,
    refetch,
  } = useMojoFetch("/api/v1/TenantIntegrations/all/" + tenantId, "get");

  // const toast = useToast();
  useEffect(() => {
    // map this data to be equal to the the one to be in the table
    const integrationsActivity = Object.values(allTenantIntegrations).map(
      ({ id, name }) => ({
        id,
        name,
        status: Boolean(integrationData[id])
          ? allIntegrationStatus.ACTIVE
          : allIntegrationStatus.INACTIVE,
      })
    );

    // sort by putting the inactive ones first
    integrationsActivity.sort((a, b) =>
      a.status === allIntegrationStatus.ACTIVE ? -1 : 1
    );

    setData(integrationsActivity);
  }, [integrationData]);

  return (
    <>
      <AddEditIntegrationForm tenantId={tenantId || ""} refetch={refetch} />
      <IntegrationsDataTable data={data} isLoading={isLoading} tenantId={tenantId || ""} refetch={refetch} />
    </>
  );
}

export default TenantIntegrations;
