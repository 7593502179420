import { BudgetForm } from '../BudgetForm';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { useMojoEffect } from 'api/useMojoEffect';
import { useMojoFetch } from 'api/useMojoFetch';

export function BudgetAddEditForm({ budgetId }) {

  const { data:budget, error } = useMojoFetch(
    `/api/v1/Budgets/${budgetId}`,
     'get'
   );
   
  return (
    <BudgetForm
      isNew={budgetId === '' ? true : false}
      btnTitle={budgetId === '' ? 'ADD BUDGET' : 'UPDATE BUDGET'}
      formTitle={budgetId === '' ? 'ADD NEW BUDGET' : 'UPDATE BUDGET'}
      btnIcon={budgetId === '' ? <AddIcon /> : <EditIcon />}
      budget={budget}  
      budgetId = {budgetId}  
    />
  );
}
