import { useState } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Spacer,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionButton,
  Switch,
  IconButton,
  Wrap,
  AccordionPanel,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';


type Group = {
  value: string;
  label: string;
};

export default function UserClientTab(props) {
  const [radioValue, setRadioValue] = useState('1');
  //const [myValue, setValue] = useState([] as any);
  //const [firstTime, setFirstTime] = useState(true);
//TODO: Fix user update but I have move the user fetch into UserCreate
  /*
  useEffect(() => {
    if (props.value !== undefined && props.value !== null && props.value.length > 0) {
      setValue(props.value);
      return;
    }    
    if (props.tenants !== undefined && props.tenants.length > 0 && firstTime) {
      const gSet = new Set<string>();
      props.tenants.forEach((t) => {
        if (t.checked) {
          gSet.add(t.TenantGroupId);
        }
      });
      const myGroups = [] as any;
      props.groups.forEach((g) => {
        if (gSet.has(g.id)) {
          myGroups.push({
            value: g.id,
            label: g.name,
          });
          props.handleToggle({ target: { checked: true } }, g.Id);
        }
      });
      setValue(myGroups);
      setFirstTime(false);
    }
  }, [firstTime, props, props.groups, props.tenants, props.value]);
*/
  const handleRadioValue = (e) => {
    setRadioValue(e);
  };

  return (
    <Box width='100%' ml='-20px'>
      {props?.tenantusers}
      <Box
        width='100%'
        borderRadius={'10px'}
        shadow={'base'}
        padding={'20px'}
        background={'white'}
      >
        <Flex width='100%' minW='500px'>
          <FormLabel>SELECT GROUP</FormLabel>
          <Spacer />
          <RadioGroup onChange={handleRadioValue} value={radioValue}>
            <Stack direction='row'>
              <Radio value='1'>All</Radio>
              <Radio value='2'>Active</Radio>
              <Radio value='3'>Inactive</Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        <Flex width='100%' gap={'20px'}>
          <Box width='100%'>
            <Select
              id='groupSelect'
              placeholder='Select Group'
              size='sm'
              options={props.groups.map(function (g) {
                if (radioValue === '1') {
                  return { value: g.id, label: g.name };
                }
                if (radioValue === '2') {
                  return { value: g.id, label: g.name };
                }
                if (radioValue === '3') {
                  return { value: '', label: '' };
                }
                return { value: g.id, label: g.name };
              })}
              isMulti
              onChange={props.handleSelect}
              value={props.value}
            />
          </Box>
        </Flex>
      </Box>
      <Box padding={'20px'} borderBottomColor={'gray.300'}>
        <Flex gap={'20px'} width={'100%'} alignItems={'center'}>
          <FormLabel margin={'0'} color={'#0899b9'} fontSize='sm'>
            CURRENT GROUPS & CLIENTS
          </FormLabel>
        </Flex>
      </Box>
      <Accordion defaultIndex={[0]} allowMultiple minW='1000px'>
        {props.value.map((group) => {
          return renderGroup(group);
        })}
      </Accordion>
    </Box>
  );

  function renderGroup(group: Group) {
    return props.groups.map((x, i) => (
      <AccordionItem
        display={x?.id.includes(group.value) ? 'block' : 'none'}
        id={i}
        key={i}
      >
        <Flex>
          <AccordionButton color={'#0899b9'} fontSize='sm'>
            <AccordionIcon color={'black'} mr='5px' />
            <b>{group.label}</b>
            <Spacer />
            <Box color='black' mr='3px'>
              All?
            </Box>
            <Switch
              size='sm'
              onChange={(e) => {
                props.handleToggle(e, group.value);
              }}
            ></Switch>
          </AccordionButton>

          <IconButton
            size='sm'
            color='red'
            colorScheme='white'
            aria-label='delete'
            icon={<DeleteIcon />}
            onClick={(e) => props.removeGroup(e, group.value)}
          />
        </Flex>

        <AccordionPanel pb={4}>
          <Wrap>
            <CheckboxGroup
            //defaultValue={props.tenants.map((t) => t.checked).map((x) => x.name)}
            >
              {props.tenants.map((tenant) => {
                function myFunc(tenant: any): boolean | undefined {
                  return tenant.checked;
                }
                return tenant.TenantGroupId === group.value ? (
                  <Checkbox
                    w='200px'
                    h='40px'
                    pl='10px;'
                    size='sm'
                    key={tenant.name}
                    onChange={(e) =>
                      props.handleTenant(tenant.TenantId, tenant.TenantGroupId)
                    }
                    isChecked={myFunc(tenant)}
                  >
                    {tenant.name}
                  </Checkbox>
                ) : (
                  ''
                );
              })}
            </CheckboxGroup>
          </Wrap>
        </AccordionPanel>
      </AccordionItem>
    ));
  }
}
