import { useState, useEffect, useRef } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import './DateFilter.scss';

function DateFilter({ handleDateChange }) {
  //const [date, setDate] = useState(new Date());
  //const date = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().setMonth(new Date().getMonth()+1)));
  const [isOpen, setIsOpen] = useState(false);
  const datepickerRef = useRef<HTMLDivElement>(null);

  
  const handleChange = (range) => {    
    const [start_date, end_date] = range;
    setStartDate(start_date);
    setEndDate(end_date);
    handleDateChange(range);
  };

  const handleClickDatepickerIcon = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      datepickerRef.current &&
      !datepickerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box className='datepicker-container' ref={datepickerRef}>
      <DatePicker
        open={isOpen}
        className='datepicker'
        selected={startDate}
        onChange={handleChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        // isClearable
        //placeholderText='10/1/2024 - 12/1/2024' // just for demo, assign default range with functionality
        customInput={
          <input
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        }
        onInputClick={handleClickDatepickerIcon}
      />
      <IconButton
        className='datepicker-icon'
        icon={<FaCalendarAlt color='var(--color-gray-500)' />}
        onClick={handleClickDatepickerIcon}
        aria-label='Select date'
        variant='no-border'
        h='1.25rem'
        w='1.25rem'
      />
    </Box>
  );
}

export default DateFilter;
