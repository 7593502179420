import { memo, useState } from 'react';
import {
  Select,
  HStack,
  Box,
  Grid,
  GridItem,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import WebsiteTrafficWidget from 'components/Widgets/WebsiteTrafficWidget';
import InventoryWidget from 'components/Widgets/InventoryWidget';
import SoldWidget from 'components/Widgets/SoldWidget';
import InvestmentChart from 'components/Charts/InvestmentChart';
import InventoryChart from 'components/Charts/InventoryChart';
import WebsiteTrafficChart from 'components/Charts/WebsiteTrafficChart';
import SoldChart from 'components/Charts/SoldChart';
//import ServiceRetentionChart from 'components/Charts/ServiceRetentionChart';
import './dashboard-layout.scss';
import DateFilter from 'components/DateFilter/DateFilter';
import InvestmentWidget from 'components/Widgets/InvestmentWidget';
import { useMojoFetch } from 'api/useMojoFetch';
import { useReportEffect } from 'api/useRepoEffect';

interface ActiveWidgetProps {
  activeWidget: string; 
}

function Dashboard({ activeWidget }: ActiveWidgetProps) {
  //const DateFilterMemo = memo(DateFilter);
  const [key, setKey] = useState(1);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  
  const { data: clients, error } = useMojoFetch('/api/v1/clients', 'get');
  const { run: getInvestmentData } = useReportEffect('/investments', 'post');
  const { run: getInventoryData } = useReportEffect('/inventory', 'post');
  const { run: getTrafficData } = useReportEffect('/websitemetrics', 'post');
  const { run: getSalesData } = useReportEffect('/sales', 'post');

  const [investmentSeries, setInvestmentSeries] = useState<{ categories: string[], dataSeries:  any[]}>({ categories: [], dataSeries:  []});
  const [inventorySeries, setInventorySeries] = useState<{
    categories: string[];
    dataSeries: any[];
  }>({ categories: [], dataSeries: [] });
  const [trafficSeries, setTrafficSeries] = useState<{
    categories: string[];
    dataSeries: any[];
  }>({ categories: [], dataSeries: [] });
  const [salesSeries, setSalesSeries] = useState<{
    categories: string[];
    dataSeries: any[];
  }>({ categories: [], dataSeries: [] });
  const [clientId, setClientId] = useState('');

  const [activeWidgetState, setActiveWidget] = useState(
    activeWidget || 'InventoryWidget'
  );
  const bg = useColorModeValue('white.100', 'black.200');

  const handleWidgetClick = (widgetName: string) => {
    setActiveWidget(widgetName);
  };

  async function handleClientChange(e) {
    if (e.target.value === '') {
      setInvestmentSeries({ categories: [], dataSeries: [] });
      setInventorySeries({ categories: [], dataSeries: [] });
      setTrafficSeries({ categories: [], dataSeries: [] });
      setSalesSeries({ categories: [], dataSeries: [] });
      setClientId('');
      setKey(key + 1);
      return;
    }
    const client = clients.find((c) => c.TenantId === e.target.value);
    if (client !== undefined && client.TenantId !== clientId) {
      await doEverything(client.TenantId, startDate, endDate);
      setClientId(client.TenantId);
    }
  }

  async function handleDateChange(range) {
    console.log('range', range);
    const [start_date, end_date] = range;
    if (startDate === null || end_date === null) {
      return;
    }
    setStartDate(start_date);
    setEndDate(end_date);
    await doEverything(clientId, start_date, end_date);
  }

  async function doEverything(client_id, start_date, end_date) {
    if (client_id === '') {
      return;
    }
    
    const postBody = {
      filters: {
        tenants: [client_id],
        startDate: start_date.valueOf(),
        endDate: end_date.valueOf(),
      },
    };

    const [investmentData, errors1] = await getInvestmentData(postBody);
    const [inventoryData, errors2] = await getInventoryData(postBody);
    const [trafficData, errors3] = await getTrafficData(postBody);
    const [salesData, errors4] = await getSalesData(postBody);

    const years: Set<number> = new Set();;
    const months: Map<number, number[]> = new Map();

    //Do all the months between startDate and endDate
    let d = new Date(start_date);
    while (d <= end_date) {
      const y = d.getFullYear();
      years.add(y);
      if (months[y] === undefined) {
        months[y] = [];
      } 
      months[y].push(d.getMonth());
    d.setMonth(d.getMonth() + 1);
    }    
    setInvestmentSeries(adjustData(investmentData.data, months, years));
    setInventorySeries(adjustData(inventoryData.data, months, years));
    setTrafficSeries(adjustData(trafficData.data, months, years));
    setSalesSeries(adjustData([], months, years));
    //setSalesSeries(adjustData(salesData, months));
    setKey(key + 1);
  }

  function adjustData(data: any[], monthSeries: Map<number, number[]>, years) {    
    const result = { categories: [] as string[], dataSeries: [] as any[] };
    const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    
    for (const year of years) {      
      for (const month of monthSeries[year]) {        
        result.categories.push(monthLabels[month]);
        if (data !== undefined && data !== null) {
          const node  = data.find(
            x => x.year === year && x.month === month
          );          
          if (node !== undefined) {
            result.dataSeries.push(node);
          } else {
            result.dataSeries.push({ year: year, month: month });
          }                   
        }
      }
    }    
    return result;
  }

  return (
    <Box className='dashboard-container' bg={bg}>
      <Box className='dashboard-header'>
        <Heading className='dashboard-heading'>HOME DASHBOARD</Heading>
        <HStack className='dashboard-filters' spacing={4}>
          <Select
            className='dashboard-filter dashboard-filter-company'
            h='2.5rem'
            w='20.625rem'
            bg={bg}
            placeholder='Company Filter'
            _placeholder={{ color: 'black.500', opacity: 1 }}
            onClick={(e) => handleClientChange(e)}
          >
            {clients.map((client) => (
              <option value={client.TenantId}>{client.name}</option>
            ))}
          </Select>
          <Select
            className='dashboard-filter dashboard-filter-department'
            h='2.5rem'
            w='20.625rem'
            bg={bg}
            placeholder='Department Filter'
          />
          <Select
            className='dashboard-filter dashboard-filter-source'
            h='2.5rem'
            w='20.625rem'
            bg={bg}
            placeholder='Source Filter'
          />
          <DateFilter handleDateChange={handleDateChange} />
        </HStack>
      </Box>
      <Grid className='dashboard-grid--layout'>
        <GridItem className='widget-column' colSpan={1}>
          <InventoryWidget
            key={key}
            data={inventorySeries}
            isActive={activeWidgetState === 'InventoryWidget'}
            onClick={() => handleWidgetClick('InventoryWidget')}
          />
          <InvestmentWidget
            key={key + 1}
            data={investmentSeries}
            isActive={activeWidgetState === 'InvestmentWidget'}
            onClick={() => handleWidgetClick('InvestmentWidget')}
          />
          <WebsiteTrafficWidget
            key={key + 2}
            data={trafficSeries}
            isActive={activeWidgetState === 'WebsiteTrafficWidget'}
            onClick={() => handleWidgetClick('WebsiteTrafficWidget')}
          />
          <SoldWidget
            key={key + 3}
            data={salesSeries}
            isActive={activeWidgetState === 'SoldWidget'}
            onClick={() => handleWidgetClick('SoldWidget')}
          />
        </GridItem>
        <GridItem className='chart-column' colSpan={1}>
          {activeWidgetState === 'InventoryWidget' && (
            <InventoryChart data={inventorySeries} key={key + 4} />
          )}
          {activeWidgetState === 'InvestmentWidget' && (
            <InvestmentChart data={investmentSeries} key={key + 5} />
          )}
          {activeWidgetState === 'WebsiteTrafficWidget' && (
            <WebsiteTrafficChart data={trafficSeries} key={key + 6} />
          )}
          {activeWidgetState === 'SoldWidget' && (
            <SoldChart data={salesSeries} key={key + 7} />
          )}
          {/*{activeWidgetState === 'ServiceRetentionWidget' && (
            <ServiceRetentionChart />
          )} */}
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Dashboard;
