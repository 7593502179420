import { useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Table, Switch } from "antd";
import type { TableColumnsType } from 'antd';
import ColumnFilters from "../../../components/DataTable/Filters/ColumnFilter/ColumnFilters";
import "../../../components/DataTable/MojoTableStyles.scss";
import "./BudgetTable.scss";


// interface BudgetsDataType {
//     key: React.Key;
//     objective?: string | null;
//     type?: string | null;
//     audience?: string | null;
//     department?: string | null;
//     channel?: string | null;
//     medium?: string | null;
//     partner?: string | null;
//     coop_eligible?: boolean | null;
//     coop_percentage?: number | null;
//     notes: string | null;
//     year?: number | null;
//     january?: number | null;
//     february?: number | null;
//     march?: number | null;
//     april?: number | null;
//     may?: number | null;
//     june?: number | null;
//     july?: number | null;
//     august?: number | null;
//     september?: number | null;
//     october?: number | null;
//     november?: number | null;
//     december?: number | null;
// }

const header = [
    "location",
    "objective",
    "type",
    "audience",
    "department",
    "channel",
    "medium",
    "partner",
    "coop_eligible",
    "coop_percentage",
    "notes",
    "year",
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
]

export const columns: TableColumnsType<any> = [
    {
    title: 'Location',
    dataIndex: header[0],
    key: 'location',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
        if (a.Location === null || b.Location === null) return 0;
        return (a.Location ?? '').localeCompare(b.Location ?? '');
        },
    },
    { 
        title: 'Objective', 
        dataIndex: header[1],
        key: 'objective',
        fixed: 'left',
        width: 150,
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
            if (a.objective === null || b.objective === null) return 0;
            return (a.objective ?? '').localeCompare(b.objective ?? '');
        },
    },
    { 
        title: 'Type', 
        dataIndex: header[2],
        key: 'type',
        fixed: 'left',
        width: 100,
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
            if (a.type === null || b.type === null) return 0;
            return (a.type ?? '').localeCompare(b.type ?? '');
        },
    },
    { 
        title: 'Audience', 
        dataIndex: header[3],
        key: 'audience',
        fixed: 'left',
        width: 125,
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
            if (a.audience === null || b.audience === null) return 0;
            return (a.audience ?? '').localeCompare(b.audience ?? '');
        },
    },
    { 
        title: 'Dept', 
        dataIndex: header[4],
        key: 'department',
        fixed: 'left',
        width: 100,
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
            if (a.department === null || b.department === null) return 0;
            return (a.department ?? '').localeCompare(b.department ?? '');
        },
    },
    { 
        title: 'Channel', 
        dataIndex: header[5], 
        key: 'channel',
        fixed: 'left',
        width: 125,
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
            if (a.channel === null || b.channel === null) return 0;
            return (a.channel ?? '').localeCompare(b.channel ?? '');
        },
    },
    { 
        title: 'Medium', 
        dataIndex: header[6], 
        key: 'medium',
        fixed: 'left',
        width: 150,
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
            if (a.medium === null || b.medium === null) return 0;
            return (a.medium ?? '').localeCompare(b.medium ?? '');
        },
    },
    { 
        title: 'Partner', 
        dataIndex: header[7], 
        key: 'partner',
        fixed: 'left',
        width: 150,
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
            if (a.partner === null || b.partner === null) return 0;
            return (a.partner ?? '').localeCompare(b.partner ?? '');
        },
    },
    { 
        title: 'COOP Eligible', 
        dataIndex: header[8],
        key: 'coop_eligible',
        fixed: 'left',
        width: 125,
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
            if (a.coop_eligible === null || b.coop_eligible === null) return 0;
            return Number(a.coop_eligible) - Number(b.coop_eligible);
        },
    },
    { 
        title: 'COOP Percentage', 
        dataIndex: header[9],
        key: 'coop_percentage',
        fixed: 'left',
        width: 150,
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
            if (a.coop_percentage === null || b.coop_percentage === null) return 0;
            return (a.coop_percentage ?? 0) - (b.coop_percentage ?? 0);
        },
    },
    { 
        title: 'Year',
        dataIndex: header[10],
        key: 'year',
        width: 100,
    },
    { 
        title: 'Jan', 
        dataIndex: header[11],
        key: 'january',
        width: 100,
    },
    { 
        title: 'Feb', 
        dataIndex: header[12],
        key: 'february',
        width: 100,
    },  
    { 
        title: 'Mar', 
        dataIndex: header[13],
        key: 'march',
        width: 100,
    },
    { 
        title: 'Apr', 
        dataIndex: header[14], 
        key: 'april',
        width: 100,
    },
    { 
        title: 'May', 
        dataIndex: header[15], 
        key: 'may',
        width: 100,
    },
    { 
        title: 'June', 
        dataIndex: header[16], 
        key: 'june',
        width: 100,
    },
    { 
        title: 'July', 
        dataIndex: header[17],
        key: 'july',
        width: 100,
    },
    { 
        title: 'Aug', 
        dataIndex: header[18],
        key: 'august',
        width: 100,
    },
    { 
        title: 'Sept', 
        dataIndex: header[19], 
        key: 'september',
        width: 100,
    },
    { 
        title: 'Oct', 
        dataIndex: header[20],
        key: 'october',
        width: 100,
    },
    { 
        title: 'Nov', 
        dataIndex: header[21], 
        key: 'november',
        width: 100,
    },
    { 
        title: 'Dec', 
        dataIndex: header[22], 
        key: 'december',
        width: 100,
    },
    // {
    //     title: ' ',
    //     key: 'controls',
    //     fixed: 'right',
    //     render: () => <span className="table-controls">Edit</span>
    // }
];

// export const budgetData: BudgetsDataType[] = [
//     {
//         key: '1',
//         objective: "Demand Capture",
//         type: "Traditional",
//         audience: "In-Market",
//         department: "All",
//         channel: "Tech Fee",
//         medium: "Google Video",
//         partner: "3 Birds Marketing",
//         coop_eligible: true,
//         coop_percentage: 25,
//         notes: "Lorem ipsum dolor sit, amet consectetur adipisicing elit",
//         february: 2000,
//         march: 3000,
//         april: 760.18,
//         may: 1000,
//         june: 2000,
//         july: 3000,
//         august: 760.18,
//         september: 1000,
//         october: 2000,
//         november: 3000,
//         december: 760.18,
//     },
//     {
//         key: '2',
//         objective: "Demand Capture",
//         type: "Digital",
//         audience: "In-Market",
//         department: "All",
//         channel: "Agency Fee",
//         medium: "Google Search",
//         partner: "3 Birds Marketing",
//         coop_eligible: true,
//         coop_percentage: 25,
//         notes: "Lorem ipsum dolor sit, amet consectetur adipisicing elit",
//         january: 1000,
//         february: 2000,
//         march: 3000,
//         april: 760.18,
//         may: 1000,
//         june: 2000,
//         july: 3000,
//         august: 760.18,
//         september: 1000,
//         october: 2000,
//         november: 3000,
//         december: 760.18,
//     },
//     {
//         key: '3',
//         objective: "Demand Creation",
//         type: "Misc.",
//         audience: "Database",
//         department: "All Sales",
//         channel: "Tech Fee",
//         medium: "Google Display",
//         partner: "700 Credit",
//         coop_eligible: true,
//         coop_percentage: 25,
//         notes: "Lorem ipsum dolor sit, amet consectetur adipisicing elit",
//         january: 1000,
//         february: 2000,
//         march: 3000,
//         april: 760.18,
//         may: 1000,
//         june: 2000,
//         july: 3000,
//         august: 760.18,
//         september: 1000,
//         october: 2000,
//         november: 3000,
//         december: 760.18,
//     },
//     {
//         key: '4',
//         objective: "Demand Capture",
//         type: "Traditional",
//         audience: "In-Market",
//         department: "All",
//         channel: "Tech Fee",
//         medium: "Google Video",
//         partner: "3 Birds Marketing",
//         coop_eligible: true,
//         coop_percentage: 25,
//         notes: "Lorem ipsum dolor sit, amet consectetur adipisicing elit",
//         january: 1000,
//         february: 2000,
//         march: 3000,
//         april: 760.18,
//         may: 1000,
//         june: 2000,
//         july: 3000,
//         august: 760.18,
//         september: 1000,
//         october: 2000,
//         november: 3000,
//         december: 760.18,
//     },
//     {
//         key: '5',
//         objective: "Demand Capture",
//         type: "Traditional",
//         audience: "In-Market",
//         department: "All",
//         channel: "Tech Fee",
//         medium: "Google Video",
//         partner: "3 Birds Marketing",
//         coop_eligible: true,
//         coop_percentage: 25,
//         notes: "Lorem ipsum dolor sit, amet consectetur adipisicing elit",
//         january: 1000,
//         february: 2000,
//         march: 3000,
//         april: 760.18,
//         may: 1000,
//         june: 2000,
//         july: 3000,
//         august: 760.18,
//         september: 1000,
//         october: 2000,
//         november: 3000,
//         december: 760.18,
//     },
//     {
//         key: '6',
//         objective: "Demand Capture",
//         type: "Traditional",
//         audience: "In-Market",
//         department: "All",
//         channel: "Tech Fee",
//         medium: "Google Video",
//         partner: "3 Birds Marketing",
//         coop_eligible: true,
//         coop_percentage: 25,
//         notes: "Lorem ipsum dolor sit, amet consectetur adipisicing elit",
//         january: 1000,
//         february: 2000,
//         march: 3000,
//         april: 760.18,
//         may: 1000,
//         june: 2000,
//         july: 3000,
//         august: 760.18,
//         september: 1000,
//         october: 2000,
//         november: 3000,
//         december: 760.18,
//     },
//     {
//         key: '7',
//         objective: "Demand Capture",
//         type: "Traditional",
//         audience: "In-Market",
//         department: "All",
//         channel: "Tech Fee",
//         medium: "Google Video",
//         partner: "3 Birds Marketing",
//         coop_eligible: true,
//         coop_percentage: 25,
//         notes: "Lorem ipsum dolor sit, amet consectetur adipisicing elit",
//         january: 1000,
//         february: 2000,
//         march: 3000,
//         april: 760.18,
//         may: 1000,
//         june: 2000,
//         july: 3000,
//         august: 760.18,
//         september: 1000,
//         october: 2000,
//         november: 3000,
//         december: 760.18,   
//     },
//     {
//         key: '8',
//         objective: "Demand Capture",
//         type: "Traditional",
//         audience: "In-Market",
//         department: "All",
//         channel: "Tech Fee",
//         medium: "Google Video",
//         partner: "3 Birds Marketing",
//         coop_eligible: true,
//         coop_percentage: 25,
//         notes: "Lorem ipsum dolor sit, amet consectetur adipisicing elit",
//         january: 1000,
//         february: 2000,
//         march: 3000,
//         april: 760.18,
//         may: 1000,
//         june: 2000,
//         july: 3000,
//         august: 760.18,
//         september: 1000,
//         october: 2000,
//         november: 3000,
//         december: 760.18,
//     },
//     {
//         key: '9',
//         objective: "Demand Capture",
//         type: "Traditional",
//         audience: "In-Market",
//         department: "All",
//         channel: "Tech Fee",
//         medium: "Google Video",
//         partner: "3 Birds Marketing",
//         coop_eligible: true,
//         coop_percentage: 25,
//         notes: "Lorem ipsum dolor sit, amet consectetur adipisicing elit",
//         january: 1000,
//         february: 2000,
//         march: 3000,
//         april: 760.18,
//         may: 1000,
//         june: 2000,
//         july: 3000,
//         august: 760.18,
//         september: 1000,
//         october: 2000,
//         november: 3000,
//         december: 760.18,
//     },
//     {
//         key: '10',
//         objective: "Demand Capture",
//         type: "Traditional",
//         audience: "In-Market",
//         department: "All",
//         channel: "Tech Fee",
//         medium: "Google Video",
//         partner: "3 Birds Marketing",
//         coop_eligible: true,
//         coop_percentage: 25,
//         notes: "Lorem ipsum dolor sit, amet consectetur adipisicing elit",
//         january: 1000,
//         february: 2000,
//         march: 3000,
//         april: 760.18,
//         may: 1000,
//         june: 2000,
//         july: 3000,
//         august: 760.18,
//         september: 1000,
//         october: 2000,
//         november: 3000,
//         december: 760.18,
//     }
// ];

// export const actualData: BudgetsDataType[] = [
//     {
//         key: '1',
//         objective: "Demand Capture",
//         type: "Traditional",
//         audience: "In-Market",
//         department: "All",
//         channel: "Tech Fee",
//         medium: "Google Video",
//         partner: "3 Birds Marketing",
//         coop_eligible: true,
//         coop_percentage: 25,
//         notes: "Lorem ipsum dolor sit, amet consectetur adipisicing elit",
//         january: 500,
//         february: 500,
//         march: 500,
//         april: 500,
//         may: 500,
//         june: 500,
//         july: 500,
//         august: 500,
//         september: 500,
//         october: 500,
//         november: 500,
//         december: 500,
//     }
// ];

function BudgetsTable({data}) {
    const [columnVisibility, setColumnVisibility] = useState<string[]>(columns.map(col => col.key as string));
    // const [useActualData, setUseActualData] = useState(false);
    
    const visibleColumnsOptions = columns.map(({ key, title }) => ({
        label: typeof title === 'string' ? title : key as string,
        value: key as string,
    }));

    const visibleColumns = columns.filter(item => columnVisibility.includes(item.key as string));
    const dataSource = data

    return (
        <Box className="budgets-table">
            <Flex className="table-header">
                {/* <Flex className="data-toggle--wrapper">
                    <Switch 
                        id="actualBudgetDataToggle"
                        checkedChildren="Actual"
                        unCheckedChildren="Budget"
                        onChange={() => setUseActualData(!useActualData)} 
                    />
                </Flex> */}
                <ColumnFilters 
                    options={visibleColumnsOptions} 
                    value={columnVisibility} 
                    onChange={setColumnVisibility} 
                />
            </Flex>
            <Table columns={visibleColumns} dataSource={dataSource} scroll={{ x: 1000, y: 500 }}
            expandable={{
                expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.notes}</p>,
                rowExpandable: (record) => record.objective !== 'Not Expandable',
            }} />
        </Box>
    );
}

export default BudgetsTable;
