import { Box, Center, Flex, Image } from '@chakra-ui/react';
import UserListDataTable from '../UserListDataTable/UserListDataTable';
import logo from '../../../logo-teal.png';
import UserForm2 from 'modules/user2/UserForm2';
import { useMojoFetch } from 'api/useMojoFetch';
import { useState } from 'react';

export default function UserList() {
  const [user, setUser] = useState();
  const [key, setKey] = useState(1);
  const [showForm, setShow] = useState(false);
  const {data: users, refetch, isLoading, error} = useMojoFetch('/api/v1/Users', 'GET');
  
  
  
  const editUser = (id) => {    
    const user = users.find((u) => u.UserId === id);
    console.log(user);
    setUser(user);
    setShow(true);
  }

  const reset = async () => {
    setShow(false);
    await refetch();
  }

  const clear = () => {
    setShow(false);
  }
  
  if (isLoading) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }
  if (error.message) {
    return <Center>{error.message}</Center>;
  }

  return (
    <>
    {showForm && (<UserForm2
        key={key}
        user={user}        
        reset = { reset }
        clear = { clear }
      />)}
    <div className='client-list'>
      <UserListDataTable data={users} editUser = { editUser }/>
    </div>
    </>
  );
}
