import {
  Button,
  FormControl,
  Textarea,
  Wrap,
  FormLabel,
  Grid,
  Input,
  Switch,
  useToast,
  Flex,
  Spacer,
  VStack,
  Card,
  useColorModeValue,
  Skeleton as ChakraSkeleton,
} from '@chakra-ui/react';
import { components } from 'api/typesgen';
import { ChangeEvent, ReactElement, useEffect, useState, FC, ReactNode, } from 'react';
import { useForm } from 'react-hook-form';
import './GoalsForm.scss';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/store';
import { useAuth0 } from '@auth0/auth0-react';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'hooks/useNavigate';
import { FloatingLabelInput } from "../../../components/FloatingLabelInput";
// import { Select } from 'chakra-react-select';
import {useSelect, Select} from "components/Select";

const Skeleton: FC<{ children: ReactNode; isLoaded: boolean }> = ({
  children,
  ...props
}) => {
  return (
    <ChakraSkeleton {...props} startColor="gray.100" endColor="gray.200">
      {children}
    </ChakraSkeleton>
  );
};

export type GoalsFormProps<T = components['schemas']['Goal']> = {
  submit: (data: T) => Promise<any>;
  values?: T;
  defaultValues?: Partial<T>;
  btnTitle: string;
  btnIcon: ReactElement;
  formTitle: string;
  onlyRequired?: boolean;
  isCreatingNew?: boolean;
  isSubmitting?: boolean;
  isLoading?: boolean;
  isNew?: boolean;
};

export function GoalsForm({
  submit,
  values,
  defaultValues,
  btnTitle,
  btnIcon,
  isCreatingNew,
  isLoading,
  isSubmitting,
  isNew,
  onlyRequired,
}: GoalsFormProps) {
  const [activeFlag, changeActive] = useState(defaultValues?.status);
  const { tenantIDs } = useAppSelector((state: RootState) => state.user);

  const { register, handleSubmit, reset, setValue }
   = useForm<NonNullable<typeof values>>({
    values,
    defaultValues: {
      status: defaultValues?.status,
      ClientId: defaultValues?.ClientId,
      goalDate: defaultValues?.goalDate,
      GoalTypeId: defaultValues?.GoalTypeId,
      goalSales: defaultValues?.goalSales,
      desiredClosingRelation: defaultValues?.desiredClosingRelation,
      goalLeads: defaultValues?.goalLeads,
      notes: defaultValues?.notes,
    },
  });

  const { getAccessTokenSilently } = useAuth0();
  const [tenantsData, setTenantsData] = useState([{ TenantId: '', name: '' }]);
  const [goalTypesData, setGoalTypesData] = useState([
    { GoalTypeId: '', description: '', status: 0 },
  ]);

  const toast = useToast();
  const navigate = useNavigate();



  const {registerSelect} = useSelect<NonNullable<typeof values>>({
		values,
		setValue,
	});

  useEffect(() => {
    const fetchGoalTypesAndTenants = async () => {
      const token = await getAccessTokenSilently();
      var hasError = false;

      fetch(process.env.REACT_APP_API_URL + '/api/v1/GoalTypes/all', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          if (response.status === 401 || response.status === 403) {
            toast({
              title: 'Unauthorized',
              status: 'error',
            });
            hasError = true;
            return response.json();
          }
          toast({
            title: response.statusText,
            status: 'error',
          });
          hasError = true;
          return response.json();
        })
        .then((json) => {
          if (!hasError) {
            setGoalTypesData(json);
          }
        });

      if (hasError) {
        return;
      }

      fetch(process.env.REACT_APP_API_URL + '/api/v1/Clients', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          if (response.status === 401 || response.status === 403) {
            toast({
              title: 'Unauthorized',
              status: 'error',
            });
            hasError = true;
            return response.json();
          }
          toast({
            title: response.statusText,
            status: 'error',
          });
          hasError = true;
          return response.json();
        })
        .then((json) => {
          if (!hasError) {
            setTenantsData(json);
            reset(defaultValues);
            changeActive(defaultValues?.status);
          }
        });
    };
    fetchGoalTypesAndTenants();
  }, [defaultValues, getAccessTokenSilently, reset, toast]);

  const goalTypesOptions = goalTypesData
    ? goalTypesData.map(({ GoalTypeId, description }) => ({
        label: description,
        value: GoalTypeId,
      }))
    : [];

    
  const tenantOptions = tenantsData
    ? tenantsData
        .filter((e) => {
          return tenantIDs.indexOf(e.TenantId) !== -1 ? true : false;
        })
        .map(({ TenantId, name }) => ({ label: name, value: TenantId }))
    : [];

  const buttonIsLoading = isLoading || isSubmitting;
  const buttonLoadingText = isLoading ? 'LOADING' : undefined;
  if (defaultValues?.goalDate) {
    defaultValues.goalDate = new Date(defaultValues?.goalDate)
      .toISOString()
      .split('T')[0];
  }

  function handleActiveChange(e: ChangeEvent<HTMLInputElement>) {
    changeActive(e.target.checked);
  }

  function mySubmit(data) {
    data.status = activeFlag;
    data.goalDate = new Date(data.goalDate);
    submit(data);
  }

  const bg = useColorModeValue("white.100", "black.100");
  const color = useColorModeValue("black.700", "white.100");

  console.log("tenantOptions:", tenantOptions);
  console.log("tenantsData:", tenantsData);
  console.log("tenantsData.:", tenantsData);

  return (
    <Card p={18} bg={bg} w={1000}>
      <form onSubmit={handleSubmit(mySubmit)}>
        <FormControl className="client-form">
          <Grid
            templateColumns='repeat(2, 1fr)'
            columnGap='2rem'
            rowGap='15px'
            marginTop='4rem'
            marginLeft='2rem'
          >
            <FormControl>
              <div className='client-form-subheader'>
                <h2>GOAL DETAILS</h2>
              </div>
            </FormControl>
            <FormControl>
              <Wrap justify='right'>
                <FormLabel htmlFor='active' mb='0'>
                  Active
                </FormLabel>
                <Switch
                  id='form-active'
                  isChecked={activeFlag}
                  {...register('status')}
                  onChange={(e) => {
                    handleActiveChange(e);
                  }}
                />
              </Wrap>
            </FormControl>


            <Skeleton isLoaded={!!tenantsData}>
              <Select
                color={color}
                placeholder='Tenant'
                {...registerSelect('ClientId', {
                  transform: (t) => t,
                  options: tenantOptions,
                  defaultValue: "string",
                  isMulti: false,
                  required: isCreatingNew,   
                })}
              />
            </Skeleton>

            <FloatingLabelInput
              placeholder="Goal Date"
              isRequired={true}
              {...register('goalDate')}
              type='date'
            />
              
              <Skeleton isLoaded={!!goalTypesData}>
              <Select
                color={color}
                placeholder='Select goal type'
                {...registerSelect('GoalTypeId', {
                  transform: (t) => t,
                  options: goalTypesOptions,
                  isMulti: false,
                  required: true, 
                })}
              />
              </Skeleton>

            <FloatingLabelInput
              placeholder="Sales Goal($)"
              isRequired={true}
              {...register('goalSales')}
              type='number'
            />

              <FloatingLabelInput
              placeholder="Lead Goal($)"
              isRequired={true}
              {...register('goalLeads')}
              type='number'
            />
          
              <FloatingLabelInput
              placeholder="Desired Closing Ratio(%)"
              isRequired={true}
              {...register('desiredClosingRelation')}
              type='number'
            />
          </Grid>
          <Flex>
          <VStack align='right' justify='right'>


          <Flex direction='column' marginTop='1rem' marginLeft='2rem'>
            <FloatingLabelInput
              placeholder="Notes"
              isRequired={false}
              {...register('notes')}
              type='text'
              style={{ width: '930px', height: '150px' }}
            />
          </Flex>


            <Flex marginTop='2rem' justify='flex-end'>
              <Button
                leftIcon={<AddIcon />}
                variant='mojoPrimary'
                size='sm'
                display={isNew ? 'none' : 'flex'}
                ml='2em'
                onClick={(e) => {
                  navigate('/goals/new');
                }}
              >
                ADD GOAL
              </Button>
              <Spacer></Spacer>
              <Button
                leftIcon={btnIcon}
                variant='mojoPrimary'
                type='submit'
                size='sm'
                isLoading={buttonIsLoading}
                loadingText={buttonLoadingText}
              >
                {btnTitle}
              </Button>
            </Flex>
          </VStack>
        </Flex>
        </FormControl>
      </form>
      </Card>
  );
}
