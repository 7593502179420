import { Box, useColorModeValue, Flex, Heading, Select, Button } from "@chakra-ui/react";
import { useNavigate } from 'hooks/useNavigate';
import BudgetAccordion from "./BudgetAccordion/BudgetAccordion";
import './Budgets.scss';
import YearSelector from "components/Button/YearSelector";
import { useMojoFetch } from "api/useMojoFetch";
import { useEffect, useState } from "react";


function Budgets() {
    const navigate = useNavigate();
    const bg = useColorModeValue("white.100", "black.200");

    const [locationId, setLocationId] = useState('whatever');
    const [selectedYears, setYears] = useState<Set<string>>(
        new Set<string>([new Date().getFullYear() + ''])
        );


    const { data: locations, error: loc_error } = useMojoFetch(
        "/api/v1/Clients/",
        "get",
    );

    const handleLocationChange = (e) => {
    setLocationId(e.target.value);    
    };

    function setSelectedYear(year: string | null): void {
        if (year != null) {
        const setArray = Array.from(selectedYears);
        const newSet = new Set<string>(setArray); 
        newSet.add(year);
        setYears(newSet);
        }
    }
    return (
        <Box className="budgets-page--wrapper" bg={bg}>
            <Flex className="budgets-page--header">
                <Flex className="budgets-header--left">
                    <Heading className="budgets-heading">BUDGETS</Heading>
                </Flex>
                <Flex className="budgets-header--right">
                    <Flex className='location-select-wrapper'>
                        <Select
                        className='goals-location-select'
                        placeholder='Location'
                        onChange={(e) => handleLocationChange(e)}
                        >
                        {loc_error.message === '' &&
                            locations.map((l) => {
                            return (
                                <option value={l.TenantId} key={l.TenantId}>
                                {l.name}
                                </option>
                            );
                            })}
                        </Select>
                    </Flex>
                    <YearSelector title="Add Budgets Year" setSelectedYear={setSelectedYear}/>
                    <Button className="budgets-upload-btn" onClick={() => {
                        navigate('/budgetsUploadForm');
                    }}>Upload Budget</Button>
                </Flex>
            </Flex>
            <Flex className="budgets-page--body">
                {Array.from(selectedYears).map(year => Number(year)).sort((a, b) => b - a).map((year, i) => (
                <BudgetAccordion year={year} key={i} location_id={locationId} />
                ))}
            </Flex>

        </Box>
    );
}

export default Budgets;