import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button, Heading, Box, Flex } from '@chakra-ui/react';
import { useNavigate } from 'hooks/useNavigate';
import './GoalsUpload.scss';
import { Uploader } from 'components/Uploader/Uploader';

const file_header = [
  'Location',
  'Type',
  'Department',
  'Year',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
  'Avg',
  'Total',
];

const columns: any[] = [
  {
    title: 'Location',
    dataIndex: file_header[0],
    key: 'location',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Location === null || b.Location === null) return 0;
      return (a.Location ?? '').localeCompare(b.Location ?? '');
    },
  },
  {
    title: 'Type',
    dataIndex: file_header[1],
    key: 'type',
    fixed: 'left',
    width: 100,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Type === null || b.Type === null) return 0;
      return (a.Type ?? '').localeCompare(b.Type ?? '');
    },
  },
  {
    title: 'Dept',
    dataIndex: file_header[2],
    key: 'department',
    fixed: 'left',
    width: 100,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Department === null || b.Department === null) return 0;
      return (a.Department ?? '').localeCompare(b.Department ?? '');
    },
  },
  {
    title: 'Year',
    dataIndex: file_header[3],
    key: 'year',
    width: 100,
  },
  {
    title: 'Jan',
    dataIndex: file_header[4],
    key: 'january',
    width: 100,
  },
  {
    title: 'Feb',
    dataIndex: file_header[5],
    key: 'february',
    width: 100,
  },
  {
    title: 'Mar',
    dataIndex: file_header[6],
    key: 'march',
    width: 100,
  },
  {
    title: 'Apr',
    dataIndex: file_header[7],
    key: 'april',
    width: 100,
  },
  {
    title: 'May',
    dataIndex: file_header[8],
    key: 'may',
    width: 100,
  },
  {
    title: 'June',
    dataIndex: file_header[9],
    key: 'june',
    width: 100,
  },
  {
    title: 'July',
    dataIndex: file_header[10],
    key: 'july',
    width: 100,
  },
  {
    title: 'Aug',
    dataIndex: file_header[11],
    key: 'august',
    width: 100,
  },
  {
    title: 'Sept',
    dataIndex: file_header[12],
    key: 'september',
    width: 100,
  },
  {
    title: 'Oct',
    dataIndex: file_header[13],
    key: 'october',
    width: 100,
  },
  {
    title: 'Nov',
    dataIndex: file_header[14],
    key: 'november',
    width: 100,
  },
  {
    title: 'Dec',
    dataIndex: file_header[15],
    key: 'december',
    width: 100,
  },
  {
    title: 'Average',
    dataIndex: file_header[16],
    key: 'average',
    width: 100,
  },
  {
    title: 'Total',
    dataIndex: file_header[17],
    key: 'total',
    width: 100,
  },
  // {
  //     title: ' ',
  //     key: 'controls',
  //     fixed: 'right',
  //     render: () => <span className="table-controls">Edit</span>
  // }
];

export function GoalsUploadForm() {
  const navigate = useNavigate();

  return (
    <>
      <Box className='goals-upload--form'>
        <Flex className='goals-upload--header'>
          <Button
            className='goals-upload--back-btn'
            leftIcon={<ChevronLeftIcon height={6} width='auto' color='cyan' />}
            variant='mojoDefault'
            onClick={() => navigate('/goals')}
          >
            GOALS
          </Button>
          <Heading className='goals-upload--heading'>
            UPLOAD GOAL FILES
          </Heading>
        </Flex>
        <Uploader
          file_header={file_header}
          columns={columns}
          maxFiles={4}
          file_type={'goals'}
          verification_url={'/api/v1/clients/byname/'}
        />
      </Box>
    </>
  );
}
