import {  Box, Center, Flex, Image, Heading } from "@chakra-ui/react";
import { useMojoFetch } from "api/useMojoFetch";
import './GroupsList.scss';
import AddEditGroupForm from "../AddEditGroupForm/AddEditGroupForm";
import GroupsTable from "../GroupsTable/GroupsTable";
import { useState } from "react";
import { useNavigate } from "hooks/useNavigate";
import logo from '../../../logo-teal.png';

export default function GroupsList() {
  const { error, data, refetch, isLoading } = useMojoFetch(`/api/v1/groups`, 'get');
const navigate = useNavigate();
  
  const [group, setGroup] = useState({id:'', name:'', agency:{name: '', id: '', providers: []}});
  const [key, setKey] = useState(1);

  const editGroup = (groupId:string) => { 
    const group = data.find(d => d.id === groupId);
    console.log(group);
    setGroup(group);
    setKey(key+1);
  }
  
  const myRefetch= async () => {
    setGroup({id:'', name:'', agency:{name: '', id: '',  providers: []}});
    await refetch();
  }
  
  const reset= () => {
    setGroup({id:'', name:'', agency:{name: '', id: '',  providers: []}});
  }

  const addEditUsers = (id:string) => {
    //console.log(e);
    const group = data.find(d => d.id === id);
    navigate(`/users/group/${group.name}/${id}`);
  }

  if (isLoading) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }
  
  if (error.message !== '') {         
    return <Center>{error.message}</Center>;
  }

  return (
    <> 
    <Flex className="groups-page--header">
          <Flex className="groups-header--left">
            <Heading className="groups-heading">GROUPS LIST</Heading>
          </Flex>
        </Flex>               
        <AddEditGroupForm key = { key }
          group = {group}
          refetch={myRefetch}
          reset = {reset}
  />
  
    <Box className="group-list" w='150%'>
      <GroupsTable
        data={data}  
        error = { error }      
        editGroup= { editGroup } 
        addEditUsers = { addEditUsers }
  />
    </Box>
    </>
  );
}


