import { useNavigate } from "hooks/useNavigate";
import { Button, Flex, Tag, Box, Text } from "@chakra-ui/react";
import { components } from "api/typesgen";
import { path } from 'ramda';
import { DataTable } from "components/DataTable/DataTable";
import { EditIcon, DeleteIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { ButtonDanger } from "components/ButtonDanger/ButtonDanger";
import { Column } from "react-table";
import { DataTableSkeleton } from "components/DataTable/Skeleton";
import InputTextFilter from "components/DataTable/Filters/InputTextFilter";
import SelectFilter from "components/DataTable/Filters/SelectFilter";

import './BudgetListDataTable.scss';
const CustomDateCell = ({ value }) => {
    const date = new Date(value); 
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const displayText = `${year}/${month}/${day}`;
    return (
      <Tag style={{fontSize:'var(--chakra-fontSizes-2xs)'}} variant='solid' colorScheme='blue' textTransform='capitalize' >
        {displayText}
      </Tag>
    );
  };
const columns: Column<components['schemas']['Budget']>[] = [
    {
        id: 'NAME',
        Header: "COMPANY",
        accessor: "name",        
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Name'}} />,
        filter: "rankedMatchSorter",
    },    
    {
        id:"DATE",
        Header:"DATE",
        accessor: "year",
        Cell:CustomDateCell,
        Filter: (props) => <InputTextFilter  column={{...props.column, filterPlaceholder: 'Date'}} />,
        filter: "rankedMatchSorter",
    },    
    {
        id: "SERVICE",
        Header: "SERVICE",
        accessor: path(['Service', 'name']),
        Filter: (props) => <SelectFilter column={{...props.column, filterPlaceholder: 'Service', className:"fix-select-2x-larger"}} />,
        Cell:({ value }) =>  <Box style={{ textAlign: 'center' }}>{value ? value : value}</Box>,
        filter: "rankedMatchSorter",
    },
    {
        id: "DEPARTMENT",
        Header: "DEPARTMENT",
        accessor: path(['Department', 'name']),
        Filter: (props) => <SelectFilter column={{...props.column, filterPlaceholder: 'Department', className:"fix-select-2x-larger"}} />,
        Cell:({ value }) =>  <Box style={{ textAlign: 'center' }}>{value ? value : value}</Box>,
        filter: "rankedMatchSorter",
    },    
    {
        id: "PAYMENT TYPE",
        Header: "PAYMENT TYPE",
        accessor: path(['PaymentType', 'name']),
        Filter: (props) => <SelectFilter column={{...props.column, filterPlaceholder: 'Payment Type', className:"fix-select-2x-larger"}} />,
        Cell:({ value }) =>  <Box style={{ textAlign: 'center' }}>{value ? value : value}</Box>,
        filter: "rankedMatchSorter",
    },
    {
        id: "AMOUNT",
        Header: "ACTUAL",
        accessor: "amount",
        Cell: (row) =>(<Box style={{ textAlign: 'center' }}><span>$ {row.value}</span></Box>),
        
    },
    {
        id: "BUDGET",
        Header: "BUDGET",
        accessor: "budget",
        Cell: (row) =>(<Box style={{ textAlign: 'center' }}><span>$ {row.value}</span></Box>),
    },
];

export type BudgetListDatatableProps = {
    data?: components['schemas']['Budget'][];
    error?: any;
    deleteBudget: (id: string) => void;
    id?: string;
}

export function BudgetListDataTable({ data, error, deleteBudget }: BudgetListDatatableProps) {
    const navigate = useNavigate();

    if (error.message !== '') {
        return (
            <Box>
                <p>An error occurred </p>
            </Box>
        );
    }
    const tableHeaderMarkup = (
        <Box className="datatable-header">
            <Box className="datatable-header-title">
                <Text fontSize={'lg22'} fontFamily={'heading'} color={"black.600"} fontWeight="3.75rem">2024 BUDGETS</Text>
            </Box>
            <Box className="datatable-header-action">
                <Button
                    rightIcon={<ChevronRightIcon height={6} width="auto" color="cyan" />}
                    variant="mojoDefault"
                    onClick={() => navigate('/budgets/new')}
                >ADD BUDGET</Button>
            </Box>
        </Box>
    );
    
    
    const tableMarkup = data ? (
        <DataTable
            data={data}
            columns={columns}
            chooseRows
            showFilter
            
        >
            {{
                rowFooter: (row: any) => (
                    <Flex gap={1}>
                        <Button variant="mojoPrimaryGhost" onClick={() => data && navigate(`/budgets/${row?.BudgetId}`)}><EditIcon /></Button>
                        <ButtonDanger onClick={() => data && deleteBudget(row?.BudgetId)}><DeleteIcon /></ButtonDanger>
                    </Flex>
                )
            }}
        </DataTable>
    ) : <DataTableSkeleton columns={columns}>
            {{ rowFooter: () => <></> }}
        </DataTableSkeleton>;

    return (
        <>
            <Box className="datatable-main datatable-controls datatable-client-list">
                {tableHeaderMarkup}
                {tableMarkup}
            </Box>
        </>
    );
}
