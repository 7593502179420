import {
	Button,
	FormControl,
	Skeleton as ChakraSkeleton,	
	Editable,
	EditableTextarea,
	EditablePreview,
} from "@chakra-ui/react";
import {components} from "api/typesgen";
import {useMojoFetch} from "api/useMojoFetch";
import {Select, useSelect} from "components/Select";
import {FC, ReactElement, ReactNode, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {FloatingLabelInput} from "components/FloatingLabelInput";
import "./VendorForm.scss";
import {useParams} from "react-router-dom";

const Skeleton: FC<{children: ReactNode; isLoaded: boolean}> = ({
	children,
	...props
}) => {
	return (
		<ChakraSkeleton {...props} startColor="gray.100" endColor="gray.200">
			{children}
		</ChakraSkeleton>
	);
};
export type VendorFormProps<
	T = components["schemas"]["Vendor"],
	V = T & {VendorIds?: string[]}
> = {
	submit: (data: V) => Promise<any>;
	values?: V;
	defaultValues?: Partial<V>;
	btnTitle: string;
	btnIcon: ReactElement;
	formTitle: string;
	onlyRequired?: boolean;
	isCreatingNew?: boolean;
	isSubmitting?: boolean;
	isLoading?: boolean;
};

export function VendorForm({
	submit,
	values,
	defaultValues,
	btnTitle,
	btnIcon,
	isCreatingNew,
	isLoading,
	isSubmitting,
	onlyRequired,
}: VendorFormProps) {
	//const {tenantId, roleId} = useParams();

	const {data: vendorTypesData, isLoading: isVendorTypesDataLoading} =
		useMojoFetch("/api/v1/VendorsTypes/all", "get");
	const {data: revieweesData, isLoading: isRevieweeDataLoading} = useMojoFetch(
		//`/api/v1/Users/admin/${roleId}`,
		`/api/v1/Users`,
		"get",
	);
	const {data: vendorRecommendsData, isLoading: isVendorRecommendsDataLoading} =
		useMojoFetch("/api/v1/VendorRecommends/all", "get");
	const {
		register,
		handleSubmit,
		formState: {errors, isDirty},
		setValue,
		control,
	} = useForm<NonNullable<typeof values>>({
		values,
		defaultValues,
	});

	const {registerSelect} = useSelect<NonNullable<typeof values>>({
		values,
		setValue,
	});
	const vendorTypesOptions = vendorTypesData
		? vendorTypesData.map(({VendorTypeId, name}) => ({
				label: name,
				value: VendorTypeId,
		  }))
		: [];
	const revieweeOptions = revieweesData
		? revieweesData.map(({firstName, lastName, UserId}) => ({
				label: firstName + " " + lastName,
				value: UserId,
		  }))
		: [];
	const vendorRecommendsOptions = vendorRecommendsData
		? vendorRecommendsData.map(({VendorRecommendId, description}) => ({
				label: description,
				value: VendorRecommendId,
		  }))
		: [];

	const isInvalid = !!errors.name || !!errors.VendorId || !!errors.VendorTypeId;

	useEffect(() => {
		register("VendorIds");
	}, [register]);
	const areSelectsLoading =
		isVendorTypesDataLoading ||
		isRevieweeDataLoading ||
		isVendorRecommendsDataLoading;
	const buttonEnabled =
		isDirty && !isInvalid && !isLoading && !isSubmitting && !areSelectsLoading;
	const buttonIsLoading = isLoading || isSubmitting || areSelectsLoading;
	const buttonLoadingText =
		isLoading || areSelectsLoading ? "LOADING" : undefined;

	if (defaultValues?.reviewDate) {
		defaultValues.reviewDate = new Date(defaultValues?.reviewDate)
			.toISOString()
			.split("T")[0];
	}
	if (defaultValues?.companyLaunch) {
		defaultValues.companyLaunch = new Date(defaultValues?.companyLaunch)
			.toISOString()
			.split("T")[0];
	}

	const [notesText, setNotesText] = useState("Notes go here...");

	useEffect(() => {
		if (defaultValues?.notes) setNotesText(defaultValues?.notes);
	}, [defaultValues]);

	return (
		<form onSubmit={handleSubmit(submit)}>
			<FormControl className="client-form">
				<div className="client-form-subheader">
					<h2>PARTNER DETAILS</h2>
				</div>
				<div className="client-form-body budget-form-body">
					<FloatingLabelInput
						defaultValue={defaultValues?.name}
						placeholder="Name"
						{...register("name", {})}
					/>
					<Skeleton isLoaded={!!vendorTypesData}>
						<Select
							placeholder="Partner type"
							isInvalid={!!errors.VendorTypeId}
							{...registerSelect("VendorTypeId", {
								transform: (t) => t,
								options: vendorTypesOptions,
								isMulti: false,
								required: isCreatingNew,
							})}
						/>
					</Skeleton>
					<Skeleton isLoaded={!!revieweesData}>
						<Select
							placeholder="Reviewee"
							isInvalid={!!errors.RevieweeId}
							{...registerSelect("RevieweeId", {
								transform: (t) => t,
								options: revieweeOptions,
								isMulti: false,
								required: isCreatingNew,
							})}
						/>
					</Skeleton>
					<Skeleton isLoaded={!!vendorRecommendsData}>
						<Select
							placeholder="AMG Recommend"
							{...registerSelect("VendorRecommendId", {
								transform: (t) => t,
								options: vendorRecommendsOptions,
								isMulti: false,
								required: isCreatingNew,
							})}
						/>
					</Skeleton>
					<FloatingLabelInput
						defaultValue={defaultValues?.presenter}
						placeholder="Presenter"
						{...register("presenter", {})}
					/>
					<FloatingLabelInput
						defaultValue={defaultValues?.reviewDate}
						placeholder="Review Date"
						type="date"
						{...register("reviewDate", {
							valueAsDate: true,
						})}
					/>
					<FloatingLabelInput
						defaultValue={defaultValues?.companyLaunch}
						placeholder="Company Launch"
						type="date"
						{...register("companyLaunch", {
							valueAsDate: true,
						})}
					/>
					<FloatingLabelInput
						defaultValue={defaultValues?.website}
						placeholder="Website URL"
						{...register("website", {})}
					/>
					<FloatingLabelInput
						defaultValue={defaultValues?.numberOfClients}
						placeholder="# of Clients"
						type="tel"
						{...register("numberOfClients", {
							valueAsNumber: true,
						})}
					/>
					<FloatingLabelInput
						defaultValue={defaultValues?.numberOfEmployees}
						placeholder="# of Employees"
						type="tel"
						{...register("numberOfEmployees", {
							valueAsNumber: true,
						})}
					/>
					<FloatingLabelInput
						defaultValue={defaultValues?.acctsPerRep}
						placeholder="Accts per Rep."
						type="tel"
						{...register("acctsPerRep", {
							valueAsNumber: true,
						})}
					/>
				</div>
				<div className="client-form-footer">
					<Editable
						defaultValue={defaultValues?.notes}
						value={notesText}
						onChange={(nextValue) => {
							setNotesText(nextValue);
						}}
					>
						<EditablePreview />
						<EditableTextarea {...register("notes", {})} />
					</Editable>
					<br />
					<Button
						isLoading={buttonIsLoading}
						isDisabled={!buttonEnabled}
						leftIcon={btnIcon}
						loadingText={buttonLoadingText}
						variant="mojoPrimary"
						type="submit"
					>
						{btnTitle}
					</Button>
				</div>
			</FormControl>
		</form>
	);
}
