import {
  Button,
  FormControl,
  Textarea,
  Flex,
  FormLabel,
  Input,
  Switch,
  Select,
  Grid,
  GridItem,
  VStack,
  Center,
  Box,
  Image,
  Spacer,
  useToast,
} from '@chakra-ui/react';

import './BudgetForm.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AddIcon } from '@chakra-ui/icons';
import logo from '../../../logo-teal.png';
import { useNavigate } from 'react-router';
import { useMojoFetch } from 'api/useMojoFetch';
import { useMojoEffect } from 'api/useMojoEffect';

type FormData = {
  activeFlag: boolean;
  TenantId: string;
  year: string;
  VendorId: string;
  ServiceId: string;
  DepartmentId: string;
  PaymentTypeId: string;
  amount: number;
  budget: number;
  notes: string;
};

export function BudgetForm(props) {

  const [isSubmitting, setSubmit] = useState(false);
  const { run: update } = useMojoEffect(`/api/v1/Budgets/${props.budgetId}`, 'put');
  const { run: create } = useMojoEffect(`/api/v1/Budgets/`, 'post');

  const { data: tenants, error: tenantError } = useMojoFetch(
    '/api/v1/Clients/',
    'get'
  );
  const { data: vendors, error: vendorError } = useMojoFetch(
    '/api/v1/Vendors/all',
    'get'
  );
  const { data: services, error: serviceError } = useMojoFetch(
    '/api/v1/Services/all',
    'get'
  );
  const { data: paymentTypes, error: paymentTypeError } = useMojoFetch(
    '/api/v1/PaymentTypes/all',
    'get'
  );
  const { data: departments, error: departmentError } = useMojoFetch(
    '/api/v1/Departments',
    'get'
  );

 const { register, handleSubmit, reset } = useForm({
    values: {
      activeFlag: props?.budget?.status,
      TenantId: props?.budget?.TenantId,
      year: props?.budget.year?.split('T')[0],
      VendorId: props?.budget?.VendorId,
      ServiceId: props?.budget?.ServiceId,
      DepartmentId: props?.budget?.DepartmentId,
      PaymentTypeId: props?.budget?.PaymentTypeId,
      amount: props?.budget?.amount,
      budget: props?.budget?.budget,
      notes: props?.budget?.notes,
    },
  });

  const [activeFlag, setActive] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();

  const submit = async (data) => {
     
    setSubmit(true);
    if (props.budgetId === '') {
      //create new
      const {activeFlag, ...data2 } = data;
      data2.amount = Number(data2.amount);
      data2.budget = Number(data2.budget);
      data2.status = activeFlag;
      const [body, createError] = await create(data2);
      if (createError === null) {
        toast({
          title: 'Budget created',
          status: 'success',
        });       
      } else {
        toast({
          title: createError.message,
          status: 'error',
        });
      }
    } else {
      //update
      const { activeFlag, TenantId, VendorId, ...data2 } = data;
      data2.status = activeFlag;
      data2.amount = Number(data2.amount);
      data2.budget = Number(data2.budget);
      const [body, updateError] = await update(data2);
      if (updateError === null) {
        toast({
          title: 'Budget updated',
          status: 'success',
        });
      } else {
        toast({
          title: updateError.message,
          status: 'error',
        });
      }
    }
    setSubmit(false);
  };


 function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setActive(e.target.checked);
  }

  
  return (
    <form onSubmit={handleSubmit(async (data) => await submit(data))}>
      <Grid templateColumns='repeat(2, 1fr)' w='620px'>
        <GridItem>
          <h2>BUDGET DETAILS</h2>
        </GridItem>
        <GridItem>
          <Flex justify='right'>
            <FormLabel>Active?</FormLabel>
            <Switch
              isChecked={activeFlag}
              {...register('activeFlag')}
              onChange={(e) => {
                handleChange(e);
              }}
            ></Switch>
          </Flex>
        </GridItem>
      </Grid>

      <Flex mb='20px'>
        <FormControl variant='floating' width='300px' mr='20px' isRequired>
          <Select
            placeholder='Tenant'
            {...register('TenantId')}
            isDisabled={!props.isNew}
          >
            {tenants.map((t) => (
              <option value={t.TenantId}>{t.name}</option>
            ))}
          </Select>
          <FormLabel style={{ paddingRight: '8px' }}>Tenant</FormLabel>
        </FormControl>
        <FormControl variant='floating' width='300px' isRequired>
          <Input type='date' {...register('year')} />
          <FormLabel style={{ paddingRight: '8px' }}>Budget Date</FormLabel>
        </FormControl>
      </Flex>
      <Flex mb='20px'>
        <FormControl variant='floating' width='300px' mr='20px' isRequired>
          <Select
            placeholder='Vendor'
            {...register('VendorId')}
            isDisabled={!props.isNew}
          >
            {vendors.map((v) => (
              <option value={v.VendorId}>{v.name}</option>
            ))}
          </Select>
          <FormLabel style={{ paddingRight: '8px' }}>Vendor</FormLabel>
        </FormControl>
        <FormControl variant='floating' width='300px' isRequired>
          <Select placeholder='Service' {...register('ServiceId')}>
            {services.map((s) => (
              <option value={s.ServiceId}>{s.name}</option>
            ))}
          </Select>
          <FormLabel style={{ paddingRight: '8px' }}>Service</FormLabel>
        </FormControl>
      </Flex>
      <Flex mb='20px'>
        <FormControl variant='floating' width='300px' mr='20px' isRequired>
          <Select placeholder='Department' {...register('DepartmentId')}>
            {departments.map((d) => (
              <option value={d.DepartmentId}>{d.name}</option>
            ))}
          </Select>
          <FormLabel style={{ paddingRight: '8px' }}>Department</FormLabel>
        </FormControl>
        <FormControl variant='floating' width='300px'>
          <Select placeholder='Payment Type' {...register('PaymentTypeId')}>
            {paymentTypes.map((t) => (
              <option value={t.PaymentTypeId}>{t.name}</option>
            ))}
          </Select>
          <FormLabel style={{ paddingRight: '8px' }}>Payment Type</FormLabel>
        </FormControl>
      </Flex>
      <Flex mb='20px'>
        <FormControl variant='floating' width='300px' mr='20px'>
          <Input
            //placeholder='Actual ($)'
            type='number'
            {...register('amount')}
          />
          <FormLabel style={{ paddingRight: '8px' }}>Actual ($)</FormLabel>
        </FormControl>
        <FormControl variant='floating' width='300px'>
          <Input {...register('budget')} type='number' />
          <FormLabel style={{ paddingRight: '8px' }}>Budget ($)</FormLabel>
        </FormControl>
      </Flex>
      <Flex>
        <VStack align='right' justify='right'>
          <FormControl variant='floating'>
            <Textarea
              className='notes'
              //placeholder='Notes'
              {...register('notes')}
              w='620px'
              mb='20px'
              height='200px'
              size='sm'
            />
            <FormLabel style={{ paddingRight: '8px' }}>Notes</FormLabel>
          </FormControl>
          <Flex>
            <Button
              leftIcon={<AddIcon />}
              variant='mojoPrimary'
              size='sm'
              display={props.isNew ? 'none' : 'flex'}
              onClick={(e) => {
                navigate('/budgets/new');
              }}
              isLoading = {isSubmitting}
            >
              ADD BUDGET
            </Button>
            <Spacer></Spacer>
            <Button
              leftIcon={props.btnIcon}
              variant='mojoPrimary'
              type='submit'
              size='sm'              
              isLoading = {isSubmitting}
            >
              {props.btnTitle}
            </Button>
          </Flex>
        </VStack>
      </Flex>
    </form>
  );
}
