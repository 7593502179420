import { Outlet, useParams } from 'react-router-dom';
import { BudgetAddEditForm } from '../AddEditBudgetForm';
import { EditorWithDetails } from 'layout/EditorWithDetails';
import { Tabs } from './Tabs';
import { Button, Heading, Flex, Box } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useNavigate } from 'hooks/useNavigate';

import { useMojoFetch } from 'api/useMojoFetch';
import { useState } from 'react';

export function BudgetDetail() {
  const [hideForm, setHideForm] = useState(false);
  const { budgetId } = useParams();
  const navigate = useNavigate();

  const { data: budget } = useMojoFetch('/api/v1/Budgets/'+ budgetId, 'get');
  const { data: channelBudgets, refetch } = useMojoFetch(
    '/api/v1/ChannelBudgets/budget/'+budgetId,
    'get',    
  );

  return (
    <>
      <EditorWithDetails>
        <div className='client-form-header'>
          <Button
            leftIcon={<ChevronLeftIcon height={6} width='auto' color='cyan' />}
            variant='mojoDefault'
            onClick={() => navigate('/budgets')}
          >
            BUDGETS LIST
          </Button>
          {!hideForm && (
            <Heading size={'md'} style={{ marginRight: 'auto' }}>
              UPDATE BUDGET
            </Heading>
          )}
          <div
            className='collapse-icon'
            role='button'
            onClick={() => setHideForm(!hideForm)}
          >
            <img
              src={`/pages/clients/list/${
                hideForm ? 'open' : 'close'
              }-arrows.svg`}
            />
          </div>
          {hideForm && channelBudgets && budget && (
            <Tabs channelBudgets={channelBudgets} budget={budget} />
          )}
        </div>
        {hideForm ? (
          <></>
        ) : (
          channelBudgets &&
          budget && <Tabs channelBudgets={channelBudgets} budget={budget} />
        )}
      </EditorWithDetails>
      <EditorWithDetails hideLeftPanel={hideForm}>       
          <BudgetAddEditForm budgetId={budgetId} />        
        <Outlet context={{ channelBudgets, budget, refetch, hideForm }} />
      </EditorWithDetails>
    </>
  );
}
