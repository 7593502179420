import {
  Button,
  FormControl,
  Skeleton as ChakraSkeleton,
  IconButton,
  Flex,
  useToast,
  Card,
  useColorModeValue,
  Switch,
  FormLabel,
} from '@chakra-ui/react';
import { components } from 'api/typesgen';
import { useMojoFetch } from 'api/useMojoFetch';
import { Select, useSelect } from 'components/Select';
import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FloatingLabelInput } from '../../../components/FloatingLabelInput';

import './ClientForm.scss';
import { Toggle } from 'components/Toggle';
import { AddIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useMojoEffect } from 'api/useMojoEffect';
import allStatesData from 'data/allStates';

const Skeleton: FC<{ children: ReactNode; isLoaded: boolean }> = ({
  children,
  ...props
}) => {
  return (
    <ChakraSkeleton {...props} startColor='gray.100' endColor='gray.200'>
      {children}
    </ChakraSkeleton>
  );
};

const labelProps = { transform: 'scale(0.85) translateY(-24px)' };

export type ClientFormProps<T = components['schemas']['Tenant']> = {
  submit: (data: T) => Promise<any>;
  values?: T;
  defaultValues?: Partial<T>;
  btnTitle: string;
  btnIcon: ReactElement;
  formTitle: string;
  onlyRequired?: boolean;
  isSubmitting?: boolean;
  isLoading?: boolean;
};

export function ClientForm({
  submit,
  values,
  defaultValues,
  btnTitle,
  btnIcon,
  isLoading,
  isSubmitting,
  onlyRequired,
}: ClientFormProps) {
  const toast = useToast();
  const [isAddClientGroup, setIsAddClientGroup] = useState(false);
  const [newClientGroupName, setNewClientGroupName] = useState('');
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { data: brandsData, isLoading: isBrandsDataLoading } = useMojoFetch(
    '/api/v1/Brands/all',
    'get'
  );
  const { data: clientTypesData, isLoading: isClientTypesDataLoading } =
    useMojoFetch('/api/v1/TenantTypes/all', 'get');
  const {
    data: clientGroupsData,
    refetch: refetchClientGroup,
    isLoading: isClientGroupsDataLoading,
  } = useMojoFetch('/api/v1/groups/', 'get');

  const { run: runCreateNewClientGroup } = useMojoEffect(
    '/api/v1/groups/',
    'post'
  );

  const statesData = allStatesData;

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    control,
  } = useForm<NonNullable<typeof values>>({
    values,
    defaultValues,
  });

  const { registerSelect } = useSelect<NonNullable<typeof values>>({
    values,
    setValue,
  });

  const brandOptions = brandsData
    ? brandsData.map(({ BrandId, name }) => ({ label: name, value: BrandId }))
    : [];

  const clientTypeOptions = clientTypesData
    ? clientTypesData.map(({ TenantTypeId, name }) => ({
        label: name,
        value: TenantTypeId,
      }))
    : [];

  const clientGroupOptions = clientGroupsData
    ? clientGroupsData.map(({ id, name }) => ({
        label: name,
        value: id,
      }))
    : [];

  const statesOptions = statesData
    ? statesData.map(({ name, value }) => ({ label: name, value: value }))
    : [];

  const isInvalid =
    !!errors.name ||
    !!errors.TenantTypeId ||
    !!errors.TenantBrandIds ||
    !!errors.TenantGroupId;

  useEffect(() => {
    register('TenantTypeId');
    register('TenantGroupId');
    register('TenantBrandIds');
  }, [register]);

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty]);

  const areSelectsLoading =
    isBrandsDataLoading ||
    isClientTypesDataLoading ||
    isClientGroupsDataLoading;
  const buttonEnabled =
    isFormDirty &&
    !isInvalid &&
    !isLoading &&
    !isSubmitting &&
    !areSelectsLoading;
  const buttonIsLoading = isLoading || isSubmitting || areSelectsLoading;
  const buttonLoadingText =
    isLoading || areSelectsLoading ? 'LOADING' : undefined;

  const handleAddNewClientGroup = async () => {
    const [createData, error] = await runCreateNewClientGroup({
      name: newClientGroupName,
    });
    if (error === null) {
      refetchClientGroup();
    } else {
      toast({ title: 'Failed to create new group', status: 'error' });
    }
    setIsAddClientGroup(false);
  };

  const bg = useColorModeValue('white.100', 'black.100');
  const color = useColorModeValue('black.700', 'white.100');
  const [activeFlag, setActiveFlag] = useState(false);

  useEffect(() => {
    setActiveFlag(values === undefined ? true : values.activeFlag);
  }, [values]);

  return (
    <Card p={18} bg={bg} w={500}>
      <form
        onSubmit={(data: any) => {
          setIsFormDirty(false);
          handleSubmit(submit)(data);
        }}
      >
        <FormControl className='client-form' isInvalid={isInvalid}>
          <div className='client-form-subheader'>
            <h2>CLIENT DETAILS</h2>
            <div className='left-form-switchs'>
              {onlyRequired ? (
                <></>
              ) : (
                <>
                  <FormLabel htmlFor='client-active' mb='0'>
                    Active?
                  </FormLabel>
                  <Switch
                    size='md'
                    isChecked={activeFlag}
                    {...register('activeFlag')}
                    onChange={(e) => {
                      setIsFormDirty(true);
                      setActiveFlag(e.target.checked);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className='client-form-body'>
            <FloatingLabelInput
              placeholder='Client Name'
              isRequired={true}
              isInvalid={!!errors.name}
              {...register('name', {
                required: 'This is required',
                minLength: { value: 4, message: 'Minimum length should be 4' },
              })}
              onChange={(e) => {
                setIsFormDirty(e.target.value !== defaultValues?.name);
              }}
            />
            <Skeleton isLoaded={!!clientGroupsData}>
              {isAddClientGroup ? (
                <Flex
                  width={'100%'}
                  gap={'10px'}
                  justifyContent={'space-between'}
                >
                  <FloatingLabelInput
                    placeholder='Input Group Name'
                    value={newClientGroupName}
                    onChange={(e) => {
                      setNewClientGroupName(e.target.value);
                    }}
                  />
                  <IconButton
                    colorScheme={!newClientGroupName ? 'blackAlpha' : 'blue'}
                    aria-label='Plus'
                    icon={<CheckIcon />}
                    disabled={!newClientGroupName}
                    onClick={() => {
                      handleAddNewClientGroup();
                    }}
                  />
                  <IconButton
                    colorScheme='red'
                    aria-label='Plus'
                    icon={<CloseIcon />}
                    onClick={() => {
                      setIsAddClientGroup(false);
                    }}
                  />
                </Flex>
              ) : (
                <Flex
                  width={'100%'}
                  gap={'10px'}
                  justifyContent={'space-between'}
                >
                  <Select
                    color={color}
                    placeholder='Client Group'
                    isInvalid={!!errors.TenantTypeId}
                    {...registerSelect('TenantGroupId', {
                      transform: (t) => {
                        setIsFormDirty(true);
                        return t;
                      },
                      options: clientGroupOptions,
                      isMulti: false,
                    })}
                    className='client-group'
                  />
                  {/*<IconButton
                    colorScheme="blue"
                    aria-label="Plus"
                    icon={<AddIcon />}
                    onClick={() => {
                      setIsAddClientGroup(true);
                    }}
                  />*/}
                </Flex>
              )}
            </Skeleton>
            <Skeleton isLoaded={!!brandsData}>
              <FormControl variant='floating' size='md' isRequired>
                <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
                  Client Brands
                </FormLabel>
                <Select
                  //placeholder='Client Brands'
                  isInvalid={!!errors.TenantTypeId}
                  {...registerSelect('TenantBrandIds', {
                    transform: (t) => {
                      setIsFormDirty(true);
                      return t;
                    },
                    options: brandOptions,
                    isMulti: true,
                    required: true,
                  })}
                />
              </FormControl>
            </Skeleton>
            <FloatingLabelInput
              placeholder='Client Website'
              {...register('mainWebsiteUrl')}
            />
            <Skeleton isLoaded={!!clientTypesData}>
              <FormControl variant='floating' size='md' isRequired>
                <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
                  Client Type
                </FormLabel>
                <Select
                  color={color}
                  isInvalid={!!errors.TenantTypeId}
                  {...registerSelect('TenantTypeId', {
                    transform: (t) => {
                      setIsFormDirty(true);
                      return t;
                    },
                    options: clientTypeOptions,
                    isMulti: false,
                    required: true,
                  })}
                />
              </FormControl>
            </Skeleton>
            {onlyRequired ? (
              <></>
            ) : (
              <>
                <FloatingLabelInput
                  placeholder='Client Code'
                  {...register('tenantCode')}
                />
                <FloatingLabelInput
                  placeholder='Client NickName'
                  {...register('tenantNickName')}
                />
                <FloatingLabelInput
                  placeholder='Client Slogan'
                  {...register('tenantSlogan')}
                />
                <FloatingLabelInput
                  placeholder='Client phone Number'
                  {...register('phone')}
                />
                <FloatingLabelInput
                  placeholder='Client Address'
                  {...register('address')}
                />
                <Skeleton isLoaded={!!statesOptions}>
                  <FormControl variant='floating' size='md' isRequired>
                    <FormLabel
                      style={{ paddingRight: '8px' }}
                      {...labelProps}
                    >State</FormLabel>
                    <Select
                      color={color}
                      isInvalid={!!errors.state}
                      {...registerSelect('state', {
                        transform: (t) => {
                          setIsFormDirty(true);
                          return t;
                        },
                        options: statesOptions,
                        isMulti: false,
                        required: true,
                      })}
                    />
                  </FormControl>
                </Skeleton>
                <FloatingLabelInput
                  placeholder='Client City'
                  {...register('city')}
                />
                <FloatingLabelInput
                  placeholder='Client Zip'
                  {...register('zip')}
                />
                <FloatingLabelInput
                  placeholder='Client Lat'
                  {...register('lat')}
                />
                <FloatingLabelInput
                  placeholder='Client Lng'
                  {...register('lng')}
                />
                <FloatingLabelInput
                  placeholder='Client Dealership USPS'
                  {...register('tenantDealerShipUSPS')}
                />
              </>
            )}
          </div>
          <div className='client-form-footer'>
            <Button
              isLoading={buttonIsLoading}
              isDisabled={!buttonEnabled}
              leftIcon={btnIcon}
              loadingText={buttonLoadingText}
              variant='mojoPrimary'
              type='submit'
            >
              {btnTitle}
            </Button>
          </div>
        </FormControl>
      </form>
    </Card>
  );
}
