import { useParams } from "react-router-dom";
import { useMojoEffect } from "api/useMojoEffect";
import { useMojoFetch } from "api/useMojoFetch";
import { useMemo } from "react";
import { useToast } from "@chakra-ui/react";
import { ClientForm } from "../ClientForm";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { useNavigate } from "hooks/useNavigate";

function randomString(length = 16) {
    const chars = 'abcdefghijklmnopqrstuvwxyz';
    let result = '';

    for (let _ of [...Array.from({ length })]) {
        const idx = Math.floor(Math.random() * chars.length);
        result += chars.charAt(idx);
    }

    return result;
}

function ClientAddForm({ onlyRequired }: { onlyRequired?: boolean }) {
    const {
        run: runCreate
    } = useMojoEffect('/api/v1/Clients/create', 'post');

    const navigate = useNavigate();
    const toast = useToast();

    return (
        <ClientForm
            isLoading={false} 
            isSubmitting={false}
            submit={async (data) => {
                console.log(data);
                const [postResult, error] = await runCreate({
                    ...data,                    
                });
                if (error === null) {
                    //navigate(`/clients/${postResult.TenantId}`);
                    navigate(`/clients`);
                    toast({
                        title: 'Client created',
                        status: 'success',
                    });
                } else {
                    toast({
                        title: 'Client creation failed',
                        status: 'error',
                    });
                }
                return postResult;
            }}
            defaultValues={{activeFlag:true}}
            btnTitle="CREATE CLIENT"
            btnIcon={<AddIcon />}
            formTitle="ADD NEW CLIENT"
            onlyRequired={onlyRequired}
        />
    );
}

function ClientEditForm({ tenantId }) {
    const {
        run: runUpdate,        
    } = useMojoEffect(`/api/v1/Clients/${ tenantId }`, 'put');

    const {
        data: clientData,       
        isLoading,
    } = useMojoFetch(`/api/v1/Clients/${ tenantId }`, 'get');

    const toast = useToast();

    const values = {
        ...clientData,
        TenantBrandIds: clientData?.TenantBrandIds || [],
    }  as typeof clientData;

    return (
        <ClientForm
            isLoading={false}
            isSubmitting={false}
            submit={async (args) => {                
                const [updatedData, error] = await runUpdate({
                    ...args,                    
                });
                if (error === null) {
                    toast({
                        title: 'Client updated',
                        status: 'success',
                    });
                } else {
                    toast({
                        title: 'Client update failed',
                        status: 'error',
                    });
                }
                return updatedData;
            }}
            values={values}
            defaultValues={values}
            btnTitle="UPDATE CLIENT"
            btnIcon={<EditIcon />}
            formTitle={`UPDATE ${clientData?.name}`}
        />
    );
}

export default function ClientAddEditForm({ onlyRequired }: { onlyRequired?: boolean }) {
    const { tenantId } = useParams();
    const form = useMemo(() => {
        if (tenantId) {
            return (
                <ClientEditForm tenantId={tenantId} />
            );
        }
        return (
            <ClientAddForm onlyRequired={onlyRequired} />
        );
    }, [tenantId, onlyRequired]);
    return form;
}