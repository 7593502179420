import { useState } from "react";
import {
Box,
useColorModeValue,
Heading,
Card,
CardHeader,
CardBody,
Stack,
Text,
Button,
Flex,
Divider,
} from "@chakra-ui/react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "react-datepicker/dist/react-datepicker.css";
import "./kpi-widgets.scss";





function WebsiteTrafficWidget({
  isActive,
  onClick,
  data,
}) {
  const [selectedKpi, setSelectedKpi] = useState('');
  const bg = useColorModeValue('white.100', 'black.200');
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'NEW',
        data: data.dataSeries.map((x) =>
          x.newUsers === undefined ? 0 : x.newUsers
        ),
        type: 'column',
        color: '#73CEE2',
      },
      {
        name: 'TOTAL',
        data: data.dataSeries.map((x) =>
          x.totalUsers === undefined ? 0 : x.totalUsers
        ),
        type: 'column',
        color: '#2F8DA2',
      },
      {
        name: 'LEAD',
        data: data.dataSeries.map((x) =>
          x.leadCount === undefined ? 0 : x.leadCount
        ),
        type: 'column',
        color: '#2F8DA2',
      },
    ],
  });
  const [chartOption, setChartOption] = useState<ApexOptions>({
    title: {
      style: {
        color: bg,
      },
    },
    chart: {
      id: '_websiteTrafficWidget',
      toolbar: {
        show: false,
      },
      width: '100%',
      background: 'white',
    },
    plotOptions: {
      bar: {
        columnWidth: '70%',
      },
    },
    stroke: {
      colors: ['transparent'],
      width: 3,
    },
    xaxis: {
      categories: data.categories,
      labels: {
        show: true,
        style: {
          colors: 'rgba(67, 66, 93, 0.5)', //purple.500
          fontSize: '0.5625rem', //9px
          fontFamily: `'Poppins', sans-serif`, // poppins
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      min: 0,
      max:
        Math.max(
          ...data.dataSeries
            .map((x) => (x.newUsers === undefined ? 0 : x.newUsers))
            .concat(
              data.dataSeries
                .map((x) => (x.totalUsers === undefined ? 0 : x.totalUsers))
                .concat(
                  data.dataSeries.map((x) =>
                    x.leadCount === undefined ? 0 : x.leadCount
                  )
                )
            )
        ) === 0
          ? 10
          : Math.max(
              ...data.dataSeries
                .map((x) => (x.newUsers === undefined ? 0 : x.newUsers))
                .concat(
                  data.dataSeries
                    .map((x) => (x.totalUsers === undefined ? 0 : x.totalUsers))
                    .concat(
                      data.dataSeries.map((x) =>
                        x.leadCount === undefined ? 0 : x.leadCount
                      )
                    )
                )
            ),

      labels: {
        style: {
          colors: 'rgba(67, 66, 93, 0.5)', //purple.500
          fontSize: '0.5625rem', //9px
          fontFamily: `'Poppins', sans-serif`, // poppins
        },
        formatter: (value) => `${value.toFixed(0)}`,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      offsetX: 0,
      offsetY: 15,
      fontSize: '8px',
      fontFamily: 'gothamMedium',
      fontWeight: 500,
      labels: {
        colors: '#393939',
      },
      itemMargin: {
        horizontal: 10,
      },
      markers: {
        strokeWidth: 1,
      },
    },
  });

  return (
    <Box
      w='100%'
      h='100%'
      maxH='200px'
      className={`widget ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <Flex className='kpi-widget--container'>
        <Box className='kpi-widget--chart'>
          <Heading size={'lg'} className='kpi-widget--heading'>
            WEBSITE TRAFFIC
          </Heading>
          <Chart
            options={chartOption}
            series={chartData.series}
            type='line'
            width='100%'
            height='100%'
          />
        </Box>

        <Flex className='kpi-widget-card--wrap'>
          <Card className='kpi-widget--card'>
            <CardHeader className='kpi-card--header'>
              <Heading className='kpi-card--heading'>
                Avg Leads Per Visitor
              </Heading>
            </CardHeader>
            <Divider />
            <CardBody className='kpi-card--body'>
              <Stack className='kpi-card--body-stack'>
                <Box className='kpi-card--pos-avg'>
                  <Text>8.3%</Text>
                </Box>

                <Box className='kpi-card--details'>
                  <Box className='kpi-card--detail-item'>
                    <Text textAlign='left'>Nation</Text>
                    <Text textAlign='right'>$--</Text>
                  </Box>
                  <Box className='kpi-card--detail-item'>
                    <Text textAlign='left'>Brand</Text>
                    <Text textAlign='right'>$--</Text>
                  </Box>
                  <Box className='kpi-card--detail-item'>
                    <Text textAlign='left'>Similar Stores</Text>
                    <Text textAlign='right'>$--</Text>
                  </Box>
                </Box>
                <Divider />
                <Box>
                  <Button
                    color={'white.100'}
                    fontSize={'xs8'}
                    bgColor={'mojo.200'}
                    p={'5px 2px'}
                    h={'auto'}
                  >
                    VIEW FULL REPORT
                  </Button>
                </Box>
              </Stack>
            </CardBody>
          </Card>
        </Flex>
      </Flex>
    </Box>
  );
}

export default WebsiteTrafficWidget;