import { useAuth0 } from "@auth0/auth0-react";
import { Center, ChakraProvider, ColorModeScript, Flex, Box, Image } from "@chakra-ui/react";
import { VendorsList } from "modules/vendors";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./modules/dashboard/index";
import {
	ClientContacts,
	TenantIntegrations,
	ClientNewForm,
	ClientUsers,
	TenantVendors,
	ClientsList,
} from "./modules/client";
import LoginRedirect from "./pages/LoginRedirect";
import { Home } from "./pages/Home";
import { Reports } from "./pages/Reports";
import { Settings } from "./pages/Settings";
import { GoogleOAuthCallback } from "./pages/GoogleOauth";
import { theme } from "./styles/theme";
import { ClientDetail } from "modules/client/ClientDetail";
import { TopBar } from "layout/TopBar";
import { Sidebar } from "layout/Sidebar";
import { Empty } from "screens/Empty";
import { Maintainance } from "screens/Maintainance";
import { VendorDetail } from "modules/vendors/VendorDetail";
import { VendorContacts } from "modules/vendors";
import { VendorServices } from "modules/vendors";
import { VendorPresentations } from "modules/vendors";
import { VendorNotes } from "modules/vendors";
import { VendorLinks } from "modules/vendors";
import logo from "./logo-teal.png";
import { UserCreate, UserList } from "modules/user"
import ProvidersList from "modules/provider/ProvidersList";
import AgenciesList from "modules/agency/AgenciesList/AgenciesList";
import GroupsList from "modules/group/GroupsList/GroupsList";
import UserList2 from "modules/user2/UserList2";
import { UserProfile } from "pages/UserProfile";
import { ReactNode } from "react";
import Footer from "layout/Footer/Footer";
import { GoalsList, GoalsDetail } from "modules/goals_old";
import { BudgetDetail, BudgetChannelDetail, BudgetNewForm, BudgetsList } from "modules/budget_old";
import Budgets from "modules/budget/Budgets";
import { BudgetsUploadForm } from 'modules/budget/BudgetsUploadForm/BudgetsUploadForm';
import { GoalsUploadForm } from "modules/goal/GoalsUploadForm/GoalsUploadForm";
import Goals from "modules/goal/Goals";
type LayoutProps = {
	children: ReactNode;
};

function App({ children }: LayoutProps) {
	const {
		isLoading,
		isAuthenticated,
		error
	} = useAuth0();

	if (isLoading) {
		return (
			<Center bg="white" h={"100%"} w={"100%"} position={'fixed'}>
				<Flex justify="center" direction="column">
					<Image src={logo} alt="Mojo Platform" />
					<Box className="loader">Loading...</Box>
					<Center>Redirecting...</Center>
				</Flex>
			</Center>
		);
	}
	if (error) {
		return <Center>Oops... {error.message}</Center>;
	}

	if (isAuthenticated) {
		return (
			<BrowserRouter>
				<ChakraProvider theme={theme}>
					<ColorModeScript />
					<Flex className="app-wrapper">
						<TopBar />
						<Sidebar />
						<Flex className="app-content--container">
							{children}
						</Flex>
                        <Footer />
					</Flex>
				</ChakraProvider>
			</BrowserRouter>
		);
	} else {
		return <LoginRedirect />;
	}
}
export default App;

export function MojoRouter() {
	return (
    <Routes>
      <Route path='/home' element={<Home />} />
      <Route index element={<Dashboard activeWidget={''} />} />
      <Route path='/reports' element={<Reports />} />
      <Route path='/organizations' element={<ProvidersList />} />
      <Route path='/agencies' element={<AgenciesList />} />
      <Route path='/groups' element={<GroupsList />} />
      <Route
        path='/users/provider/:name/:id'
        element={<UserList2 label='Organization' url='Organizations' />}
      />
      <Route
        path='/users/agency/:name/:id'
        element={<UserList2 label='Agency' url='Agencies' />}
      />
      <Route
        path='/users/group/:name/:id'
        element={<UserList2 label='Group' url='Groups' />}
      />
      <Route
        path='/users/tenant/:name/:id'
        element={<UserList2 label='Client' url='Clients' />}
      />
      <Route path='/clients' element={<ClientsList />} />
      <Route path='/clients/new' element={<ClientNewForm />} />
      <Route path='/clients/:tenantId' element={<ClientDetail />}>
        <Route path='' element={<ClientContacts />} />
        <Route path='contacts' element={<ClientContacts />} />
        <Route path='users' element={<ClientUsers />} />
        <Route path='integrations' element={<TenantIntegrations />} />
        <Route path='vendors' element={<TenantVendors />} />
        <Route path='info' element={<Maintainance />} />
        <Route path='*' element={<Empty />} />
      </Route>

      {/* new budget */}
      <Route path='/budgets' element={<Budgets />} />
      <Route path='/budgetsUploadForm' element={<BudgetsUploadForm />} />
      <Route path='/budgets/new' element={<BudgetsUploadForm />} />

      {/* old budget */}
      <Route path='/budget_old/' element={<BudgetsList />} />
      <Route path='/budget_old/:budgetId' element={<BudgetDetail />}>
        <Route path=':channelTypeId' element={<BudgetChannelDetail />} />
        <Route path='*' element={<Empty />} />
      </Route>
      <Route path='/budget_old/new' element={<BudgetNewForm />} />
      {/* /old budget */}

      <Route path='/partners' element={<VendorsList />} />
      <Route path='/partners/new' element={<VendorDetail />} />
      <Route path='/partners/:partnerId' element={<VendorDetail />}>
        <Route path='' element={<VendorContacts />} />
        <Route path='notes' element={<VendorNotes />} />
        <Route path='presentations' element={<VendorPresentations />} />
        <Route path='services' element={<VendorServices />} />
        <Route path='links' element={<VendorLinks />} />
        <Route path='contacts' element={<VendorContacts />} />
        <Route path='*' element={<Empty />} />
      </Route>

      {/* new goals */}
      <Route path='/goals' element={<Goals />} />
      <Route path='/goalsUploadForm' element={<GoalsUploadForm />} />

      {/* old goals */}
      <Route path='/goals_old' element={<GoalsList />} />
      <Route path='/goals_old/new' element={<GoalsDetail title='ADD GOAL' />} />
      <Route
        path='/goals_old/:goalId'
        element={<GoalsDetail title='UPDATE GOAL' />}
      />
      {/* /old goals */}

      <Route path='/login' element={<LoginRedirect />} />
      <Route path='/settings' element={<Settings />} />
      <Route path='/oauth/google/callback' element={<GoogleOAuthCallback />} />
      <Route path='/users' element={<UserList />} />
      <Route path='/users/new' element={<UserCreate />}></Route>
      <Route path='/users/modify/:userId' element={<UserCreate />}></Route>
      <Route path='/userprofile/:userId' element={<UserProfile />}></Route>
      <Route path='*' element={<Empty />} />
    </Routes>
  );
}
