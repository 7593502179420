import { useParams } from "react-router-dom";
import { useNavigate } from "hooks/useNavigate";
import { components } from "api/typesgen";
import { uniq } from "ramda";
import { Box } from '@chakra-ui/react'

export type TabsProps = {
    channelBudgets: components['schemas']['ChannelBudget'][];
    budget: components['schemas']['Budget'];
  }

export function Tabs({ channelBudgets, budget }: TabsProps) {
    const { budgetId, channelTypeId: currentChannelTypeId } = useParams();
    
    const getTabClass = (channelTypeId: string) => {
        return channelTypeId === currentChannelTypeId ? 'item-menu-active' : 'item-menu';
    }

    const getTabItemClass = (channelTypeId: string) => {
        return channelTypeId === currentChannelTypeId ? 'menu-label-active' : 'menu-label gothamhtf-book-normal-gray-16px';
    }

    const navigate = useNavigate();

    const types = uniq(channelBudgets.map(cb => cb.ChannelType));

    const tabsMarkup = types.map(type => (
        <Box className={getTabClass(type.ChannelTypeId)}
                onClick={() => navigate(`/budgets/${budgetId}/${type.ChannelTypeId}`)}
        >
            <Box className={getTabItemClass(type.ChannelTypeId)}>
                {type.name}
            </Box>
        </Box>
    ))

    return (
        <Box className="submenu-wrapper submenu">
            <Box className="submenu-container submenu">
                {tabsMarkup}
            </Box>
        </Box>
    );
}