import { useState } from "react";
import {
  Box,
  useColorModeValue,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Stack,
  StackDivider,
  Text,
  Button,
  Icon,
  Divider,
  Flex
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "react-datepicker/dist/react-datepicker.css";
import "./charts.scss";



function WebsiteTrafficChart({data}) {

    const [selectedKpi, setSelectedKpi] = useState("");
    const bg = useColorModeValue("white.100", "black.200");
    const [chartData, setChartData] = useState({
      series: [
        {
          name: 'NEW',
          data: data.dataSeries.map((x) =>
            x.newUsers === undefined ? 0 : x.newUsers
          ),
          type: 'column',
          color: '#73CEE2',
        },
        {
          name: 'TOTAL',
          data: data.dataSeries.map((x) =>
            x.totalUsers === undefined ? 0 : x.totalUsers
          ),
          type: 'column',
          color: '#2F8DA2',
        },
        {
          name: 'LEAD',
          data: data.dataSeries.map((x) =>
            x.leadCount === undefined ? 0 : x.leadCount
          ),
          type: 'column',
          color: '#2F8DA2',
        },
      ],
    });
    const [chartOption, setChartOption] = useState<ApexOptions>({
      title: {
        style: {
          color: bg,
        },
      },
      chart: {
        id: 'website-traffic--chart',
        toolbar: {
          show: false,
        },
        width: '100%',
        background: 'white',
      },
      plotOptions: {
        bar: {
          columnWidth: '80%',
        },
      },
      stroke: {
        colors: ['transparent'],
        width: 5,
      },
      xaxis: {
        categories: data.categories,
        labels: {
          show: true,
          style: {
            colors: '#43425D', //purple.500
            fontSize: '0.8125rem', //13px
            fontFamily: `'Poppins', sans-serif`, // poppins
          },
        },
      },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max: Math.max(
          ...data.dataSeries
            .map((x) => (x.newUsers === undefined ? 0 : x.newUsers))
            .concat(
              data.dataSeries
                .map((x) => (x.totalUsers === undefined ? 0 : x.totalUsers))
                .concat(
                  data.dataSeries.map((x) =>
                    x.leadCount === undefined ? 0 : x.leadCount
                  )
                )
            )
        )
          === 0 ? 10
          : Math.max(
              ...data.dataSeries
                .map((x) => (x.newUsers === undefined ? 0 : x.newUsers))
                .concat(
                  data.dataSeries
                    .map((x) => (x.totalUsers === undefined ? 0 : x.totalUsers))
                    .concat(
                      data.dataSeries.map((x) =>
                        x.leadCount === undefined ? 0 : x.leadCount
                      )
                    )
                )
            ),

        labels: {
          style: {
            colors: '#43425D', //purple.500
            fontSize: '0.8125rem', //13px
            fontFamily: `'Poppins', sans-serif`, // poppins
          },
          formatter: (value) => `${value.toFixed(0)}`,
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetX: 0,
        offsetY: 15,
        fontSize: '14px',
        markers: {
          strokeWidth: 1,
        },
        itemMargin: {
          horizontal: 15,
        },
      },
    });

    return (
        <Box className="main-chart--container">
            <Flex className="main-chart-heading--col" width="100%">
                <Heading className="main-chart--heading">WEBSITE TRAFFIC</Heading>
            </Flex>
            <Flex className="main-chart--col">
                <Box className="main-chart--wrapper">
                    <Chart className="main-chart"
                        options={chartOption}
                        series={chartData.series}
                        type="line"
                        height={500}           
                    />
                </Box>
                <Flex className="main-chart-card--col">
                    <Card className="main-chart--card">
                        <CardHeader className="chart-card--header">
                            <Heading className="chart-card--title">Avg Leads Per Visitor</Heading>
                            <Divider orientation='horizontal' />
                        </CardHeader>
                        
                        <CardBody className="chart-card--body">
                            <Stack
                                divider={<StackDivider />}
                                spacing="4"
                                align="center"
                            >
                                <Flex className="chart-card-kpi positive">
                                    <Text className="chart-card-kpi--pos-value">
                                        8.3%
                                    </Text>
                                </Flex>
                                <Flex className="chart-card-kpi-data">
                                    <Box className="kpi-data-item">
                                        <Text className="kpi-data-item--label" textAlign="left">Nation</Text>
                                        <Text className="kpi-data-item--value" textAlign="right">$--</Text>
                                    </Box>
                                    <Box className="kpi-data-item">
                                        <Text className="kpi-data-item--label" textAlign="left">Brand</Text>
                                        <Text className="kpi-data-item--value" textAlign="right">$--</Text>
                                    </Box>
                                    <Box className="kpi-data-item">
                                        <Text className="kpi-data-item--label" textAlign="left">Similar Stores</Text>
                                        <Text className="kpi-data-item--value" textAlign="right">$--</Text>
                                    </Box>
                                </Flex>
                                <Flex className="chart-card-kpi-footer">
                                    <Button>
                                        VIEW FULL REPORT
                                    </Button>
                                </Flex>
                            </Stack>
                        </CardBody>
                    </Card>
                </Flex>
            </Flex>
        </Box>
    );
}

export default WebsiteTrafficChart;