import React from "react";
import ReactDOM from "react-dom/client";
import Auth0ProviderWithHistory from "auth/auth0-provider";
import App from "./App";
import "./styles/globals.css";

// Old fonts to cleanup later once we remove use cases from existing components
import '@fontsource/figtree';
import '@fontsource/inter';
// /Old Fonts

import { Provider } from "react-redux";
import { store } from "store/store";
import { MojoRouter} from "./App";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(

    <React.StrictMode>
        <Auth0ProviderWithHistory>
            <Provider store={store}>
                <App>
                    <MojoRouter />
                </App>
            </Provider>
        </Auth0ProviderWithHistory>
    </React.StrictMode>
);

