import {
  Box,
  useColorModeValue,
  Flex,
  Heading,
  Button,
  Select,
} from '@chakra-ui/react';
import './Goals.scss';
import YearSelector from 'components/Button/YearSelector';
import GoalsAccordion from './GoalsAccordion/GoalsAccordion';
import { useNavigate } from 'react-router';
import { useMojoFetch } from 'api/useMojoFetch';
import { useState } from 'react';

function Goals() {
  const [locationId, setLocationId] = useState('whatever');
  const [selectedYears, setYears] = useState<Set<string>>(
    new Set<string>([new Date().getFullYear() + ''])
  );
  const { data: locations, error: loc_error } = useMojoFetch(
    '/api/v1/clients',
    'get'
  );

  const navigate = useNavigate();
  const bg = useColorModeValue('white.100', 'black.200');

  const handleLocationChange = (e) => {
    setLocationId(e.target.value);    
  };

  function setSelectedYear(year: string | null): void {
    if (year != null) {
      const setArray = Array.from(selectedYears);
      const newSet = new Set<string>(setArray); 
      newSet.add(year);
      setYears(newSet);
    }
  }

  return (
    <Box className='goals-page--wrapper' bg={bg}>
      <Flex className='goals-page--header'>
        <Flex className='goals-header--left'>
          <Heading className='goals-heading'>GOALS</Heading>
        </Flex>
        <Flex className='goals-header--right'>
          <Flex className='location-select-wrapper'>
            <Select
              className='goals-location-select'
              placeholder='Location'
              onChange={(e) => handleLocationChange(e)}
            >
              {loc_error.message === '' &&
                locations.map((l) => {
                  return (
                    <option value={l.TenantId} key={l.TenantId}>
                      {l.name}
                    </option>
                  );
                })}
            </Select>
          </Flex>
          <YearSelector
            title='Add Goals Year'
            setSelectedYear={setSelectedYear}
          />
          <Button
            className='goals-upload-btn'
            onClick={() => {
              navigate('/goalsUploadForm');
            }}
          >
            Upload Goals
          </Button>
        </Flex>
      </Flex>
      <Flex className='goals-page--body'>
        {Array.from(selectedYears).map(year => Number(year)).sort((a, b) => b - a).map((year, i) => (
          <GoalsAccordion year={year} key={i} location_id={locationId} />
        ))}
      </Flex>
    </Box>
  );
}

export default Goals;
