import {AddIcon} from "@chakra-ui/icons";
import {Box, Button, FormControl, useToast, Input} from "@chakra-ui/react";
import {useMojoEffect} from "api/useMojoEffect";
import {FloatingLabelInput} from "components/FloatingLabelInput";
import {Select, useSelect} from "components/Select";
import {Toggle} from "components/Toggle";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import "./AddEditPresentationsForm.scss";
import {usePostFile} from "hooks/usePostFile";

export type AddEditPresentationsFormProps = {
	vendorId: string;
	refetch: () => void
};

export function AddEditPresentationsForm({
	vendorId,
	refetch,
}: AddEditPresentationsFormProps) {
	const [file, setFile] = useState<File | null>(null);
	const {
		run: runCreate,
		data: createdData,
		error: createdError,
		isLoading: isRunningCreate,
	} = usePostFile(
		`/api/v1/VendorFiles/upload/` + vendorId,
		{method: "POST"},
		file
	);

	const {
		handleSubmit,
		formState: {errors},
		reset,
	} = useForm<NonNullable<typeof createdData>>();

	const toast = useToast();

	return (
		<form
			onSubmit={handleSubmit(async (data) => {
				await runCreate({file: file as File});
				console.log("usePostFile result: ", createdData);
				if (createdData) {
					await refetch();
					reset();
					toast({
						title: "Vendor presentation created",
						status: "success",
					});
				} else {
					toast({
						title: "Vendor presentation creation failed",
						status: "error",
					});
				}
				return createdData;
			})}
		>
			<FormControl>
				<div className="form-upper-wrapper add-edit-presentations-form">
					<div className="form-upper form">
						<div className="form-upper-title">ADD NEW PRESENTATION</div>
					</div>
					<div className="form">
						<div className="input-group presentation-upload">
							<Input
								className="custom-file-input"
								type="file"
								onChange={(e) => {
									setFile(e.target.files?.[0] || null);
								}}
							/>
						</div>
						<div className="bt-add">
							<Button
								isLoading={isRunningCreate}
								variant="mojoPrimary"
								type="submit"
							>
								<AddIcon />
							</Button>
						</div>
					</div>
				</div>
			</FormControl>
		</form>
	);
}
