import AccordionComponent from 'components/Accordion/AccordionComponent';
import GoalsTable from '../GoalsTable/GoalsTable';
import { useMojoFetch } from 'api/useMojoFetch';
import { useMojoEffect } from 'api/useMojoEffect';
import { useEffect, useState } from 'react';

function GoalsAccordion({ year, location_id }) {
  const [data, setData] = useState([]);

  const { runWithId } = useMojoEffect(`/api/v1/goals`, 'get');

  useEffect(() => {
    const myFunc = async () => {
      const [myData, _] = await runWithId(
        `?tenantId=${location_id}&year=${year}`
      );
      setData(myData);
    };
    myFunc();
  }, [location_id, year]);

  return (
    <AccordionComponent title={year + ' Goals'}>
      <GoalsTable data={data} />
    </AccordionComponent>
  );
}

export default GoalsAccordion;
