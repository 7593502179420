import { useToast } from '@chakra-ui/react';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
} from '@chakra-ui/icons';

import {
  Box,
  Button,
  Center,
  Flex,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
} from '@chakra-ui/react';

import UserForm from '../UserForm/UserForm';
import { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
//import UserPermissionsTab from '../UserPermissionsTab/UserPermissionsTab';
import UserClientTab from '../UserClientTab/UserClientTab';
import { useForm, SubmitHandler } from 'react-hook-form';
import logo from '../../../logo-teal.png';
import { useMojoEffect } from 'api/useMojoEffect';
import { useMojoFetch } from 'api/useMojoFetch';

type Selection = {
  value: string;
  label: string;
};

type Tenant = {
  TenantId: string;
  name: string;
  TenantGroupId: string;
  checked: boolean;
};

export type FormData = {
  firstName: string;
  lastName: string;
  phone: string;
  blockFlag: boolean;
  activeFlag: boolean;
  email: string;
  userRole: string;
  userRoleOption: string;
  password: string;
  passwordCopy: string;
};

type UserDto = {
  UserId: string;
  firstName: string;
  lastName: string;
  phone: string;
  blockFlag: boolean;
  activeFlag: boolean;
  email: string;
  //userRoleOption: string;
  //userRole: String;
  roles: any;
  password: string;
  //tenantIds: String[];
  //permissionIds: String[];
};

export default function UserCreate(props) {
  const [isLoading, setLoading] = useState(false);
  const { userId } = useParams();
  const [myUserId, setUserId] = useState(userId);

  const toast = useToast();

  const [activeFlag, setActiveFlag] = useState(true);
  const [blockFlag, setBlockFlag] = useState(false);
  const [defaultRole, setRole] = useState('');
  const [roleOptions, setRoleOptions] = useState([] as any);

  const [values, setData] = useState<FormData>({
    firstName: '',
    lastName: '',
    phone: '',
    blockFlag: blockFlag,
    activeFlag: activeFlag,
    email: '',
    userRoleOption: '',
    userRole: '',
    password: '',
    passwordCopy: '',
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ values });

  //const [permissions, handleAllPermissions] = useState(new Set<number>());
  //const [isLoading, setLoading] = useState(false);
  const [formLoading, setLoadingState] = useState(true);
  const [value, changeSelect] = useState<Selection[]>([]);
  const [hideForm, setHideForm] = useState(false);

  const navigate = useNavigate();

  const { data: roles, error: roleError } = useMojoFetch(
    '/api/v1/roles/',
    'get'
  );
  const { data: groups, error: groupError } = useMojoFetch(
    '/api/v1/groups/',
    'get'
  );
  const { data: originalTenants, error: tenantError } = useMojoFetch(
    '/api/v1/Clients/',
    'get'
  );

  const { runWithId: runUser } = useMojoEffect(`/api/v1/users/`, 'get');
  const { run: createUser } = useMojoEffect(`/api/v1/users/`, 'post');
  const { run: updateUser } = useMojoEffect(`/api/v1/users/${userId}`, 'put');
  const [tenants, setAllTenants] = useState<Tenant[]>([]);
  const [, toggleCheckbox] = useState(tenants);
  const [defaultRoleValue, setDefaultRoleValue] = useState({
    value: '',
    label: '',
  });
  const [defaultRoleOption, setDefaultRoleOption] = useState({
    value: '',
    label: '',
  });

  useEffect(() => {
    const fetchAll = async () => {
      const myTenants = originalTenants.map((d) => ({ ...d, checked: false }));
      //Get the full user
      if (userId !== undefined) {
        const [user, userError] = await runUser(userId);
        setData(user);
        setActiveFlag(user.activeFlag);
        setBlockFlag(user.blockFlag);

        if (
          user.roles.Organizations !== undefined &&
          user.roles.Organizations.length > 0
        ) {
          setDefaultRoleValue({
            value: user.roles.Organizations[0].role_id,
            label: user.roles.Organizations[0].name,
          });

          setDefaultRoleOption({
            value: user.roles.Organizations[0].entity_id,
            label: user.roles.Organizations[0].entity,
          });
        } else if (
          user.roles.Agencies !== undefined &&
          user.roles.Agencies.length > 0
        ) {
          setDefaultRoleValue({
            value: user.roles.Agencies[0].role_id,
            label: user.roles.Agencies[0].name,
          });

          setDefaultRoleOption({
            value: user.roles.Agencies[0].entity_id,
            label: user.roles.Agencies[0].entity,
          });
        } else if (
          user.roles.Groups !== undefined &&
          user.roles.Groups.length > 0
        ) {
          setDefaultRoleValue({
            value: user.roles.Groups[0].role_id,
            label: user.roles.Groups[0].name,
          });

          setDefaultRoleOption({
            value: user.roles.Groups[0].entity_id,
            label: user.roles.Groups[0].entity,
          });
        } else if (
          user.roles.Clients !== undefined &&
          user.roles.Clients.length > 0
        ) {
          setDefaultRoleValue({
            value: user.roles.Clients[0].role_id,
            label: user.roles.Clients[0].name,
          });
          user.roles.Clients.forEach((t) => {
            const tenant = myTenants.find(x => x.TenantId === t.entity_id);
            if (tenant) {
              tenant.checked = true;
            }
          });          
        }

        /*
        //check the permissions
        handleAllPermissions(
          new Set<number>(new Set<number>(user.permissionIds))
        );
        */
      }
      setAllTenants([...myTenants]);
      setLoadingState(false);
    };
    fetchAll();
  }, [originalTenants]);

  if (formLoading) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setLoading(true);
    const { userRole, userRoleOption, ...cleanUser } = data;
    var userDto: UserDto;

    const role = roles.find((x) => x.Id === userRole);
    const roleOption = roleOptions.find((x) => x.id === userRoleOption);

    const myRoles = {
      Organizations: [] as any,
      Agencies: [] as any,
      Groups: [] as any,
      Clients: [] as any,
    };

    if (role.name.includes('Organization')) {
      myRoles.Organizations.push({
        role_id: role.Id,
        name: role.name,
        entity_id: roleOption.id,
        entity: roleOption.name,
      });
    } else if (role.name.includes('Agency')) {
      myRoles.Agencies.push({
        role_id: role.Id,
        name: role.name,
        entity_id: roleOption.id,
        entity: roleOption.name,
      });
    } else if (role.name.includes('Group')) {
      myRoles.Groups.push({
        role_id: role.Id,
        name: role.name,
        entity_id: roleOption.id,
        entity: roleOption.name,
      });
    } else if (role.name.includes('Client')) {
      tenants
        .filter((t) => t.checked === true)
        .forEach((t) => {
          myRoles.Clients.push({
            role_id: role.Id,
            name: role.name,
            entity_id: t.TenantId,
            entity: t.name,
          });
        });
    }

    userDto = {
      ...cleanUser,
      UserId: '',
      //permissionIds: [],
      roles: myRoles,
      /*
      tenantIds: tenants
        .filter((t) => t.checked === true)
        .map((t) => t.TenantId),
        */
    };

    if (userDto.password !== data.passwordCopy) {
      setLoading(false);
      toast({
        title: 'Passwords do not match',
        status: 'error',
      });
      return;
    }

    if (userId !== undefined) {
      userDto.UserId = userId ?? '';
      const [body, error] = await updateUser(userDto);
      if (error === null) {
        toast({
          title: 'User updated',
          status: 'success',
        });
      } else {
        toast({
          title: 'User update failed',
          status: 'error',
        });
      }
    } else {
      const [body, error] = await createUser(userDto);
      if (error === null) {
        toast({
          title: 'User created',
          status: 'success',
        });
        setActiveFlag(data.activeFlag);
        setBlockFlag(data.blockFlag);
        setUserId(body.UserId);
      } else {
        toast({
          title: 'User creation failed',
          status: 'error',
        });
      }
    }
    setLoading(false);
  };

  /*
  const handlePermission = (e: React.ChangeEvent<HTMLInputElement>): void => {
    var foo = new Set<number>(permissions);

    if (e.target.checked) {
      foo.add(Number(e.target.id));
    } else {
      foo.delete(Number(e.target.id));
    }
    handleAllPermissions(foo);
  };
*/
  const removeGroup = (e, tenantGroupId: string): void => {
    var foo = [...value];
    foo = foo.filter((f) => f.value !== tenantGroupId);
    changeSelect(foo);
  };

  const handleSelect = (e) => {
    changeSelect([...e]);
  };

  const handleTenant = (tenantId, groupId) => {
    var tenant = tenants.find((t) => t.TenantId === tenantId);

    if (tenant != null) {
      tenant.checked = !tenant?.checked;
    }
    var foo = [...tenants];
    toggleCheckbox(foo);
  };

  const handleToggle = (e, groupId) => {
    var group = groups.find((g) => g.id === groupId);

    var someTenants = tenants.filter((t) => t.TenantGroupId === groupId);

    if (e.target.checked) {
      if (group != null) {
        group.checked = true;
        someTenants.map((st) => (st.checked = true));
      }
    } else {
      if (group != null) {
        group.checked = false;
        someTenants.map((st) => (st.checked = false));
      }
    }

    setAllTenants([...tenants]); //useState() hook so that userClient part of form re-renders
  };

  const resetForm = () => {
    reset({
      firstName: '',
      lastName: '',
      phone: '',
      blockFlag: false,
      activeFlag: true,
      email: '',
      userRoleOption: '',
      userRole: '',
      password: '',
      passwordCopy: '',
    });

    setActiveFlag(true);
    setBlockFlag(false);

    setRoleOptions([]);
    setDefaultRoleValue({ value: '', label: '' });
    setDefaultRoleOption({ value: '', label: '' });

    //handleAllPermissions(new Set<number>());
    let someTenants = [...tenants];
    someTenants.map((st) => (st.checked = false));
    setAllTenants(someTenants);

    setUserId('');
  };

  return (
    <>
      <Flex
        ml='2em'
        top='7em'
        width={!hideForm ? '500px' : '150px'}
        position='fixed'
      >
        <Button
          leftIcon={
            <ChevronLeftIcon height={6} width={'auto'} color={'cyan'} />
          }
          variant={'mojoDefault'}
          onClick={() => navigate('/users')}
        >
          <Text>USERS</Text>
        </Button>

        {!hideForm && (
          <Center w='150px'>
            <Text>
              <b>
                {myUserId === '' || myUserId === undefined
                  ? 'ADD NEW USER'
                  : 'UPDATE USER'}
              </b>
            </Text>
          </Center>
        )}
        {!hideForm && <Spacer />}
        {!hideForm && (
          <ArrowLeftIcon
            ml='10px'
            mt='6pt'
            w={3}
            h={4}
            onClick={() => setHideForm(!hideForm)}
          ></ArrowLeftIcon>
        )}
        {hideForm && (
          <ArrowRightIcon
            ml='10px'
            mt='6pt'
            w={3}
            h={4}
            onClick={() => setHideForm(!hideForm)}
          ></ArrowRightIcon>
        )}
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex width='100%'>
          <Box display={hideForm ? 'none' : 'flex'}>
            <UserForm
              isLoading = {isLoading}
              register={register}
              userId={myUserId}
              roles={roles}
              activeFlag={activeFlag}
              blockFlag={blockFlag}
              resetForm={resetForm}
              setRole={setRole}
              setRoleOptions={setRoleOptions}
              defaultRoleValue={defaultRoleValue}
              defaultRoleOption={defaultRoleOption}
            />
          </Box>
          {hideForm && <Box w='150px' mr='50px' />}
          {defaultRole.includes('Client') && (
            <Tabs width={'full'}>
              <TabList>
                <Tab>CLIENTS</Tab>
                {/*<Tab>PERMISSIONS</Tab>*/}
              </TabList>
              <TabPanels>
                <TabPanel>
                  <UserClientTab
                    groups={groups}
                    tenants={tenants}
                    handleTenant={handleTenant}
                    handleToggle={handleToggle}
                    handleSelect={handleSelect}
                    removeGroup={removeGroup}
                    value={value}
                  />
                </TabPanel>
                {/*<TabPanel>
                <UserPermissionsTab
                  handlePermission={handlePermission}
                  permissions={Array.from(permissions)}
      />
              </TabPanel>*/}
              </TabPanels>
            </Tabs>
          )}
        </Flex>
      </form>
    </>
  );
}
