import { Input as ChakraInput } from "@chakra-ui/react";

export type InputProps = {
    placeholder?: string;
    type?: string;
    value?: string;
    className?: string;
    defaultValue?: string | number;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Input(props: InputProps) {
    const {
        placeholder,
        value,
        defaultValue,
        onChange,
        type,
        className,
    } = props;
    return (
        <ChakraInput variant="none"
            bg="white"
            borderColor="#D5D5D5"
            borderWidth="2px"
            borderRadius="8px"
            color="black"
            fontSize="12px"
            fontWeight="500"
            lineHeight="13px"
            fontStyle="normal"
            padding="20px"
            value={value}
            type={type}
            defaultValue={value}
            onChange={onChange}
            placeholder={ placeholder || "Enter text" }
        />
    );
}