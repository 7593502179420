import React, { useState } from "react";
import {
    Tooltip,
    VStack,
    IconButton,
    Spacer,
    useColorModeValue
} from "@chakra-ui/react";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdOutlineClose } from "react-icons/md";
import SidenavItems from "./SideNavItems";
import "./sidebar.scss";

export interface SidenavItem {
    to: string;
    icon: any;
    label: string;
}

export interface SidenavProps {
    navItems: SidenavItem[];
}

export function UTSidenav({ navItems }: SidenavProps) {
    const [expanded, setExpanded] = useState(false);


    const toggleSidebar = () => {
        setExpanded(!expanded);
    };

    const bg = useColorModeValue("black.600", "white.200");

    return (
        <div id="mojoSidebar" className="mojo-sidebar"
            style={{                
                width: expanded ? "210px" : "70px",
            }}
        >
            {/* Sidebar content */}
            <VStack className="sidebar-content" bg={bg}>
                <SidenavItems navItems={navItems} isOpen={expanded} />
                {/* Toggle button */}
                <Tooltip label={expanded ? 'collapse' : 'expand'}>
                    <IconButton className="sidebar-toggle-button"
                        onClick={toggleSidebar}
                        aria-label={expanded ? "Collapse" : "Expand"}
                        icon={expanded ? <MdOutlineClose /> : <RxHamburgerMenu />}
                        style={{
                            left: expanded ? "auto" : "15px", // Adjust position based on sidebar state
                            right: expanded ? "0px" : "auto", // Adjust position based on sidebar state
                        }}
                    />
                </Tooltip>

            </VStack>
        </div>
    );
}

export default UTSidenav;
