import { Box, Center, Flex, Image, useToast } from "@chakra-ui/react";
import { useMojoFetch } from "api/useMojoFetch";
import { useMojoEffect } from "api/useMojoEffect";
import { ClientListDataTable } from "../ClientListDataTable";
import { useCallback, useEffect, useState } from "react";
import './ClientsList.scss';
import { useNavigate } from "hooks/useNavigate";
import logo from '../../../logo-teal.png';

function ClientsList() {
  //const {selectedGroupId, selectedTenantId}=useAppSelector((state:RootState)=>state.app)
  
  const { runWithId: runDelete } = useMojoEffect(`/api/v1/Clients/`, 'delete');   
   //const [data, setListData] = useState([] as any);
  const {data: clients, error: fetchError, refetch, isLoading} = useMojoFetch('/api/v1/Clients/', 'get');

  const toast = useToast();
 
  const deleteClient = useCallback(async (tenantId: string) => {
    const [delResult, error] = await runDelete(tenantId)
    if (error === null) {
      toast({
        title: 'Client deleted',
        status: 'success',
      });
      await refetch();
    } else {
      toast({
        title: 'Failed to delete client',
        status: 'error',
      })
    }
  }, [runDelete, toast, refetch]);

const navigate = useNavigate();

  const addEditUsers = (id:string) => {
    const client = clients?.find(d => d.TenantId === id);
    navigate(`/users/tenant/${client?.name}/${client.TenantId}`);
  }

  if (isLoading) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }
  
  if (fetchError.message !== '') {         
    return <Center>{fetchError.message}</Center>;
  }
  
  return (
    <div className="client-list">
      <ClientListDataTable
        data={clients}
        error={false}
        deleteClient={deleteClient}
        addEditUsers = { addEditUsers }
      />
    </div>
  );
}

export default ClientsList;
