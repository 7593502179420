import { Outlet, useParams, useLocation } from 'react-router-dom';
import { EditorWithDetails } from 'layout/EditorWithDetails';
import { AddEditGoalsForm } from '../AddEditGoalsForm';
import { Button, Heading } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useNavigate } from 'hooks/useNavigate';
import './GoalsDetail.scss';
import { useMojoFetch } from 'api/useMojoFetch';
import { useMojoEffect } from 'api/useMojoEffect';

export function GoalsDetail(props) {
  const { goalId } = useParams();
  const navigate = useNavigate();
  const { runWithId: run } = useMojoEffect(`/api/v1/Goals/`, 'get');
  const { pathname } = useLocation();

  const fetchGoals = async () => {
    if (goalId !== undefined) {
      await run(goalId);
    } else {
      await run('');
    }
  };

  useMojoEffect(() => {fetchGoals()}, []);

  return (
    <EditorWithDetails>
      <>
        <div className='client-form-header'>
          <Button
            leftIcon={<ChevronLeftIcon height={6} width='auto' color='cyan' />}
            variant='mojoDefault'
            onClick={() => navigate('/goals')}
          >
            GOALS LIST
          </Button>
          <Heading size={'md'}>{props.title}</Heading>
        </div>
        <AddEditGoalsForm />
      </>
      <div className='client-detail-detail'></div>
    </EditorWithDetails>
  );
}
