import {Box, Center, Flex, Image, useToast} from "@chakra-ui/react";
import {useMojoFetch} from "api/useMojoFetch";
import {useMojoEffect} from "api/useMojoEffect";
import {useCallback} from "react";
import {GoalsListDataTable} from "../GoalsListDataTable";
import "./GoalsList.scss";
import {useAppSelector} from "store/hooks";
import {RootState} from "store/store";
import logo from '../../../logo-teal.png';

export function GoalsList() {
    const {selectedGroupId, selectedTenantId} = useAppSelector(
        (state: RootState) => state.app
    );
    const toast = useToast();
    //const {error, data, refetch} = useMojoFetch(`/api/v1/Goals/all/groups/${selectedGroupId}/tenants/${selectedTenantId}`, "get");
    const {error, data, refetch, isLoading} = useMojoFetch(`/api/v1/Goals/all`, "get");
    const {run: runDelete} = useMojoEffect(
        "/api/v1/Goals/",
        "delete"
    );
    const deleteGoal = useCallback(
        async (goalId: string) => {
            const [body, error] = await runDelete(goalId);
            if (error === null) {
                toast({
                    title: "Vendor deleted",
                    status: "success",
                });
                await refetch();
            } else {
                toast({
                    title: "Failed to delete vendor",
                    status: "error",
                });
            }
        },
        [runDelete, toast, refetch]
    );
    if (isLoading) {
        return (
          <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
            <Flex justify='center' direction='column'>
              <Image src={logo} alt='Mojo Platform' />
              <Box className='loader'></Box>
              <Center>Loading...</Center>
            </Flex>
          </Center>
        );
      }
      
      if (error.message !== '') {         
        return <Center>{error.message}</Center>;
      }
    return (
        <div className="goals-list">
            <GoalsListDataTable
                data={data}              
                deleteVendor={deleteGoal}
            />
        </div>
    );
}
