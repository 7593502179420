import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../logo-teal.png";
import { Center, Flex, Button, Image, Box } from "@chakra-ui/react";

const LoginRedirect = () => {
  const { loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    logout();
    // @ts-ignore
    document.getElementById("login").click();
  }, [logout]);

  return (
    <Center bg="white" h={"100%"} w={"100%"} position={'fixed'}>
      <Flex justify="center" direction="column">
        <Image src={logo} alt="Mojo Platform" />
        <Box className="loader">Loading...</Box>
        <Center>Redirecting...</Center>

        <Button
          id="login"
          style={{ visibility: "hidden" }}
          onClick={() =>
            loginWithRedirect({
              redirectUri: window.location.origin,
            })
          }
        >
          Login
        </Button>
      </Flex>
    </Center>
  );
};

export default LoginRedirect;
