import {
  Box,
  Center,
  Flex,
  VStack,
  Image,
  Text,
  useToast,
  Heading,
  Progress,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { usePapaParse } from 'react-papaparse';
import { Table } from 'antd';
import { useMojoEffect } from 'api/useMojoEffect';
import './Uploader.scss';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const baseStyle = {
  flex: 1,
  display: 'flex',
  height: '135px',
  align: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 5,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

type BFile = {
  file: any;
  tenantId: string;
};
export function Uploader({
  file_header,
  columns,
  maxFiles,
  file_type,
  verification_url,
}) {
  const { runWithId: verifyEntity } = useMojoEffect(verification_url, 'get');
  const { runWithId: getPresignedUrl } = useMojoEffect(
    `/api/v1/app/signedUrl/`,
    'get'
  );
  const [isDropping, setIsDropping] = useState(false);
  const [data, setData] = useState([]);
  const toast = useToast();
  const [files, setFiles] = useState<BFile[]>([]);
  const [progress, setProgress] = useState(0);
  const { readString } = usePapaParse();

  // Pad a number to 2 digits
  const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  // Get timezone offset in ISO format (+hh:mm or -hh:mm)
  const getTimezoneOffset = (date) => {
    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? '+' : '-';
    return diff + pad(tzOffset / 60) + ':' + pad(tzOffset % 60);
  };

  const toISOStringWithTimezone = (date: Date) => {
    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      getTimezoneOffset(date)
    );
  };

  function renameFile(name: string): string {
    const file = files.find((f) => f.file.name === name);
    if (file === undefined) {
      throw new Error(`File named ${name} not found in budgets list`);
    }
    const new_filename =
      file_type.substring(0, file_type.length-1) +
      '_' +
      file.tenantId +
      '_' +
      toISOStringWithTimezone(new Date()) +
      '.csv';
    return new_filename;
  }

  async function uploadEverything() {
    setProgress(1); //just to trigger the UPLOADING label
    const totalFileSize = files.reduce(
      (partialSum, a) => partialSum + a.file.size,
      0
    );
    let uploadedFileSize = 0;
    for (const f of files) {
      const [resultURL, errors] = await getPresignedUrl(
        renameFile(f.file.name) + `?file_type=${file_type}`
      );
      if (errors !== null) {
        toast({
          title: `Could not upload ${f.file.name}`,
          status: 'error',
        });
      } else {
        try {
          //upload file
          uploadFile(resultURL.url, f.file);
        } catch (err) {
          console.error(err);
          toast({
            title: `Could not upload ${f.file.name}`,
            status: 'error',
          });
        }
      }
      uploadedFileSize += f.file.size;
      setProgress((100 * (uploadedFileSize + 0.0)) / totalFileSize);
    }
    setProgress(100);
    await delay(1000);
    setProgress(0);
    setFiles([]);
    setData([]);
  }

  function uploadFile(url, file) {
    const fileData = new FormData();
    fileData.append('file', file);
    var client = new XMLHttpRequest();
    client.open('PUT', url, false);
    client.send(file);
  }

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { 'text/csv': [] },
      maxFiles: maxFiles,
      multiple: false,
      validator: myValidator,
      onDropAccepted: (e) => {
        setIsDropping(false);
      },
      onDropRejected: (e) => {
        setIsDropping(false);
      },
      onError: (e) => {
        setIsDropping(false);
      },

      onDrop: (acceptedFiles) => {
        setIsDropping(true);
        //Parse contents
        acceptedFiles.forEach((file) => {
          var reader = new FileReader();
          reader.readAsText(file);
          reader.onloadend = function (e) {
            parseContents(file, reader.result);
          };
        });
      },
    });

  function parseContents(myFile, contents) {
    readString(contents.trim(), {
      header: true,
      worker: true,
      complete: async (records) => {
        if (records.errors !== undefined && records.errors.length > 0) {
          toast({
            title: `${records.errors[0].message} at row ${records.errors[0].row}`,
            status: 'error',
          });
          return;
        }
        const tenantId = await parseRecords(records.data);
        if (tenantId !== null) {
          const new_list = [...files];
          new_list.push({ file: myFile, tenantId: tenantId });
          setFiles(new_list);
        }
      },
    });
  }

  const parseRecords = async (tableData) => {
    //3. Check that dealer exists
    const dealer_name = tableData[0].Location.trim();
    const [dealer, errors] = await verifyEntity(dealer_name);
    if (errors !== null) {
      toast({
        title: `Could not validate dealer`,
        status: 'error',
      });
      return null;
    }

    //4. Check for missing columns
    let columns_missing = false;
    const firstRow = tableData[0];
    file_header.forEach((h) => {
      if (firstRow[h] === undefined) {
        columns_missing = true;
      }
    });

    if (columns_missing) {
      toast({
        title: `Columns missing`,
        status: 'error',
      });
      return null;
    }

    //5. Check for additional columns
    if (Object.keys(firstRow).length > file_header.length) {
      toast({
        title: `There are additional columns`,
        status: 'error',
      });
      return null;
    }

    //6. Check that the file has a single dealer
    for (const row of tableData) {
      if (row.Location.trim() !== dealer_name) {
        toast({
          title: `Multiple dealers in files`,
          status: 'error',
        });
        return null;
      }
    }

    //7.Check that the file concerns a single year
    const year = tableData[0].Year.trim();
    for (const row of tableData) {
      if (row.Year.trim() !== year) {
        toast({
          title: `Multiple years in files`,
          status: 'error',
        });
        return null;
      }
    }
    setData(data.concat(tableData));
    console.log(data);
    return dealer.TenantId;
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  function myValidator(myFile) {
    //1. check that we are under the max. file limit
    if (myFile.kind && files.length === maxFiles) {
      toast({
        title: `You cannot add more than ${maxFiles} files`,
        status: 'error',
      });
      return { message: '', code: '' };
    }
    if (files.length === maxFiles) {
      return { message: '', code: '' };
    }
    //2. Check if file already exists
    if (!myFile.kind && files.some((f) => f.file.name === myFile.name)) {
      toast({
        title: `Duplicate file`,
        status: 'error',
      });
      return { message: '', code: '' };
    }

    return null;
  }

  return (
    <>
      <Box className='uploader--container'>
        {isDropping && (
          <Center>
            <Spinner />
          </Center>
        )}

        <div {...getRootProps({ style })}>
          <VStack>
            <Flex>
              {files.map((f, i) => (
                <VStack mr='30px' key={i}>
                  <Center>
                    <Image
                      boxSize='80px'
                      src='https://upload.wikimedia.org/wikipedia/commons/c/c6/.csv_icon.svg'
                      alt='csv icon'
                    />
                  </Center>
                  <Center>
                    <Text style={{ fontSize: 'var(--chakra-fontSizes-2xs)' }}>
                      {f.file.name}
                    </Text>
                  </Center>
                </VStack>
              ))}
            </Flex>
            <Box>
              <Center>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </Center>
            </Box>
          </VStack>
        </div>
      </Box>
      {data.length > 0 && (
        <Flex className='upload-progress--container'>
          <Text className='progress-text'>Progress</Text>
          <Progress
            className='progress-bar'
            value={progress}
            width='100%'
            colorScheme='cyan'
          />
        </Flex>
      )}

      {data.length > 0 && (
        <Box className='uploader-preview--container'>
          <Box className='preview-content'>
            <Heading className='preview-heading'>UPLOAD PREVIEW</Heading>
            <Table
              className='preview-table'
              columns={columns}
              dataSource={data}
              scroll={{ x: 1000, y: 500 }}
            />
          </Box>
          <Flex className='uploader-preview--footer'>
            <Button
              className='reset-button'
              size='sm'
              colorScheme='cyan'
              color='white'
              onClick={() => {
                setFiles([]);
                setData([]);
              }}
            >
              RESET
            </Button>
            <Button
              className='complete-upload-button'
              size='sm'
              colorScheme='cyan'
              color='white'
              onClick={async () => await uploadEverything()}
            >
              COMPLETE UPLOAD
            </Button>
          </Flex>
        </Box>
      )}
    </>
  );
}
