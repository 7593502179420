import {
	useToast,	
	CardBody,
	Card,
	Link,
	Input,
	Button,	
} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {useMojoFetch} from "api/useMojoFetch";
import {useMojoEffect} from "api/useMojoEffect";
import {ArrayElement} from "lib/types/utils";
import {
	CheckIcon,
	CloseIcon,
	CopyIcon,
	DeleteIcon,
	EditIcon,	
} from "@chakra-ui/icons";
import {AddEditLinksForm} from "../AddEditLinksForm1";
import "./Links.scss";
import {useState} from "react";

function VendorLinks() {
	const {partnerId} = useParams();
	const [vendorLinkId, setVendorLinkId] = useState('');
	const {error, data, refetch} = useMojoFetch(
		`/api/v1/VendorLinks/all/${partnerId}`,
		"get"		
	);
	const {run: runDelete } = useMojoEffect(`/api/v1/VendorLinks/${vendorLinkId}`, "delete");
	const {run: runUpdate } = useMojoEffect(
		`/api/v1/VendorLinks/${vendorLinkId}`,
		"patch"
	);
	const toast = useToast();

	const deleteVendorLink = async (vendorLink) => {
		setVendorLinkId(vendorLink.VendorId);
		const [body, error] = await runDelete();
		if (error === null) {
			toast({
				title: "Vendor link deleted",
				status: "success",
			});
			await refetch();
		} else {
			toast({
				title: "Failed to delete Vendor link",
				status: "error",
			});
		}
	};

	const updateVendorLink = async (
		idx: number,
		vendorLink: any
	) => {
		if (!data) {
			return;
		}
		const fullVendorLink = {
			...data[idx],
			...vendorLink,
		};
		const [updatedData, error] = await runUpdate({
			...fullVendorLink,
			VendorId: fullVendorLink.VendorId,
			id: fullVendorLink.VendorLinkId,
		});
		setIsEditing(-1);
		if (updatedData) {
			toast({
				title: "Vendor link updated",
				status: "success",
			});
			await refetch();
		} else {
			toast({
				title: "Failed to update Vendor link",
				status: "error",
			});
		}
	};

	const [isEditing, setIsEditing] = useState(-1);
	const [editingVendorLinkUrl, setEditingVendorLinkUrl] = useState("");

	return (
		<>
			<AddEditLinksForm vendorId={partnerId || ""} refetch={refetch} />
			<h5>LINKS</h5>
			<div className="presentations-files-list">
				{data?.map((vendorLinkObject, index) => (
					<Card direction="row" overflow="hidden" variant="elevated" size="sm">
						<CardBody>
							<div className="items-left">
								<CopyIcon />
								{isEditing === index ? (
									<Input
										name="url"
										defaultValue={vendorLinkObject.url}
										onChange={(e) => {
											setEditingVendorLinkUrl(e.target.value);
										}}
									/>
								) : (
									<Link href="#" isExternal size="sm">
										{vendorLinkObject.url}
									</Link>
								)}
							</div>
							<div className="icons-right">
								{isEditing === index ? (
									<>
										<Button
											variant="ghost"
											colorScheme="green"
											onClick={() => {
												updateVendorLink(index, {
													VendorId: partnerId,
													url: editingVendorLinkUrl,
												});
											}}
										>
											<CheckIcon />
										</Button>
										<Button
											variant="ghost"
											colorScheme="gray"
											onClick={() => setIsEditing(-1)}
										>
											<CloseIcon />
										</Button>
									</>
								) : (
									<>
										<EditIcon
											onClick={() => {
												isEditing === -1
													? setIsEditing(index)
													: setIsEditing(-1);
											}}
										/>
										<DeleteIcon
											onClick={() => deleteVendorLink(vendorLinkObject)}
										/>
									</>
								)}
							</div>
						</CardBody>
					</Card>
				))}
			</div>
		</>
	);
}

export default VendorLinks;
