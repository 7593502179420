import { InlineEditableDataTable } from "components/DataTable/InlineEditableDataTable";
import { components } from "api/typesgen";
import { Column } from "react-table";
import { path } from "ramda";
import { DataTableSkeleton } from "components/DataTable/Skeleton";
import { useMojoFetch } from "api/useMojoFetch";
import { useCallback } from "react";
import { Select } from "components/Select";
import { Tag } from "@chakra-ui/react";
import "./datatables.css";
import { EditableSwitchCell } from "components/EditableSwitchCell";

const columns: (Column<components["schemas"]["VendorContact"]> & {
    label?: (contact: components["schemas"]["VendorContact"]) => string;
})[] = [
    {
        Header: "FIRST NAME",
        accessor: "firstName",
    },
    {
        Header: "LAST NAME",
        accessor: "lastName",
    },
    {
        Header: "E-MAIL",
        accessor: "email",
    },
    {
        Header: "PHONE",
        accessor: "phone",
    },
    {
        Header: "TITLE",
        accessor: "title",
    },
    {
        Header: "STATUS",
        accessor: "status",
        Cell: ({ value }) =>
            value ? (
                <Tag
                    variant="solid"
                    colorScheme="green"
                    textTransform="uppercase"
                >
                    active
                </Tag>
            ) : (
                <></>
            ),
    },
];

const tableHeader = <></>;

const EditableSelectCell = ({
    value,
    row: { index: rowIdx },
    column: { id: cellKey },
    updateRowData,
}) => {
    const { data: jobTypesData } = useMojoFetch(
        "/api/v1/JobTypes/all",
        "get"
    );

    const jobTypeOptions = jobTypesData
        ? jobTypesData.map(({ JobTypeId, name }) => ({
              label: name,
              value: JobTypeId,
          }))
        : [];

    const onChange = useCallback(
        (selectedValue: unknown) => {
            if (updateRowData) {
                updateRowData(rowIdx, cellKey, selectedValue);
            }
        },
        [rowIdx, cellKey, updateRowData]
    );

    return (
        <Select
            className="min-width-20"
            value={value}
            onChange={onChange}
            options={jobTypeOptions}
        />
    );
};

export type ContactsDataTableProps<T = components["schemas"]["VendorContact"]> = {
    error: any;
    deleteContact: (contact: T) => Promise<void>;
    updateContact: (idx: number, contact: Partial<T>) => Promise<void>;
    data?: T[];
};

export function ContactsDataTable({
    data,
    error,
    deleteContact,
    updateContact,
}: ContactsDataTableProps) {
    const tableMarkup = data ? (
        <InlineEditableDataTable
            data={data}
            columns={columns}
            updateRow={updateContact}
            deleteRow={deleteContact}
            cells={{
                RoleId: EditableSelectCell,
                status: EditableSwitchCell,
            }}
        >
            {{ tableHeader }}
        </InlineEditableDataTable>
    ) : (
        <DataTableSkeleton columns={columns}>
            {{ rowFooter: () => <></> }}
        </DataTableSkeleton>
    );

    return (
        <div className="datatable datatable-contact-list">
            <div className="datatable-controls screen">{tableMarkup}</div>
        </div>
    );
}
