import { PayloadAction, createSlice, combineReducers } from "@reduxjs/toolkit";
import integrationsSlice, {
  IntegrationsState,
  initialIntegrationsState,
} from "./integrationsSlice";
import { components } from "api/typesgen";

interface AppState {
  selectedGroupId: string;
  selectedTenantId: string;
  Roles: components["schemas"]["Role"][];
  integrations: IntegrationsState;
}

const initialState: AppState = {
  selectedGroupId: "",
  selectedTenantId: "",
  Roles: [],
  integrations: initialIntegrationsState,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    selectTenantId: (state, action: PayloadAction<string>) => {
      state.selectedTenantId = action.payload;
    },
    selectGroupId: (state, action: PayloadAction<string>) => {
      state.selectedGroupId = action.payload;
    },
    setRoles: (state, action: PayloadAction<components["schemas"]["Role"][]>) =>{
      state.Roles = action.payload;
    }
  },
});

export const { selectTenantId, selectGroupId, setRoles } = appSlice.actions;

export default appSlice;
