import { FormControl, FormLabel, Switch, useCheckbox, useControllableProp, useControllableState } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

export type ToggleProps = {
  control: any;
  label: string;
  name: string;
  rightLabel?: boolean;
  size?: 'lg' | 'md' | 'sm';
  isChecked?: boolean;
  setIsFormDirty;
};

export function Toggle({
  control,
  label,
  name,
  rightLabel = false,
  size = 'md',
  isChecked = false,
  setIsFormDirty,
}: ToggleProps) {

const [checked, setChecked] = useState(false);

useEffect(() => {
  setChecked(isChecked);
}, [isChecked])
  
  function changeState(event): void {
    setChecked(event.target.checked);
    setIsFormDirty(true);    
  }

  return (
    <FormControl display='flex' alignItems='center'>
      {!rightLabel && (
        <FormLabel htmlFor={name} mb='0'>
          {label}
        </FormLabel>
      )}
      
          
      <Switch isChecked={checked} onChange={changeState}></Switch>

      {rightLabel && (
        <FormLabel htmlFor={name} mb='0' ml={2}>
          {label}
        </FormLabel>
      )}
    </FormControl>
  );

  



}

