import { BudgetAddEditForm } from "../AddEditBudgetForm";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Button, Heading, Box } from "@chakra-ui/react";
import { useNavigate } from "hooks/useNavigate";
import './BudgetNewForm.scss';

export function BudgetNewForm() {
  const navigate = useNavigate();
  return (
    <>
      <Box className="client">
        <Box className="client-form-header" mb='3.5em'>
          <Button
            leftIcon={<ChevronLeftIcon height={6} width="auto" color="cyan" />}
            variant="mojoDefault"
            onClick={() => navigate('/budgets')}
          >BUDGETS</Button>
          <Heading size={"md"}>ADD NEW BUDGET</Heading>
        </Box>
        <Box>
          <BudgetAddEditForm budgetId={''}/>
        </Box>
      </Box>
    </>
  );
}
