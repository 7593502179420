import { useToast, Checkbox } from "@chakra-ui/react";
// import { IntegrationsDataTable } from "../IntegrationsDataTable";
import { AddEditServicesForm } from "../AddEditServicesForm";
import { useParams } from "react-router-dom";
import { useMojoFetch } from "api/useMojoFetch";
import { useMojoEffect } from "api/useMojoEffect";
import { ArrayElement } from "lib/types/utils";
import "./Services.scss";
import { useAuth0 } from "@auth0/auth0-react";

function VendorServices() {    
  const { getAccessTokenSilently } = useAuth0();
    const { partnerId } = useParams();
    const { error, data, refetch } = useMojoFetch(
        "/api/v1/Services/all",
        "get"
    );
    const {
        error: selectedServicesError,
        data: selectedServices,
        refetch: selectedServicesRefetch,
    } = useMojoFetch(`/api/v1/VendorServices/all/${partnerId}`, "get");

    const { runWithId: runDelete } = useMojoEffect(
        "/api/v1/VendorServices/",
        "delete"
    );

    const { run: runCreate } = useMojoEffect(
        "/api/v1/VendorServices/",
        "post"
    );

    const toast = useToast();

    const deleteSelectedVendorService = async (
        service
    ) => {
        const [body, deleteStatus] = await runDelete(service.VendorServiceId);
        if (deleteStatus == null) {
            toast({
                title: "Selected service deleted",
                status: "success",
            });
            selectedServicesRefetch();
        } else {
            toast({
                title: "Failed to delete selected service",
                status: "error",
            });
        }
    };

    const updateSelectedServices = async (service) => {
       const [body, error] = await runCreate(service);
        if (error === null) {
            toast({
                title: "Selected services updated",
                status: "success",
            });
            selectedServicesRefetch();
        } else {
            toast({
                title: "Failed to update selected services",
                status: "error",
            });
        }
    };

    return (
        <>
            <AddEditServicesForm VendorId={partnerId || ""} refetch={refetch} />
            <h5>SELECT SERVICES</h5>
            <div className="services-list">
                {data?.map((x, i) => (
                    <Checkbox
                        key={i}
                        value={x.ServiceId}
                        onChange={(e) => {
                            const service = {
                                VendorId: partnerId,
                                ServiceId: x.ServiceId,
                            };
                            if (!e.currentTarget.checked && selectedServices) {
                                const selectedService = selectedServices?.find(
                                    (selectedService) =>
                                        selectedService.ServiceId ===
                                        x.ServiceId
                                );

                                const deleteServicePayload = {
                                    VendorServiceId:
                                        selectedService?.VendorServiceId ||
                                        "new",
                                    VendorId: partnerId || "new",
                                    ServiceId: x.ServiceId,
                                };
                                deleteSelectedVendorService(
                                    deleteServicePayload
                                );
                            } else {
                                updateSelectedServices(service);
                            }
                            console.log("clicked", x);
                        }}
                        defaultChecked={
                            selectedServices?.find(
                                (selectedService) =>
                                    selectedService.ServiceId === x.ServiceId
                            )
                                ? true
                                : false
                        }
                    >
                        {x.name}
                    </Checkbox>
                ))}
            </div>
        </>
    );
}

export default VendorServices;
