import { useCallback, useState } from "react";

export type UsePostOptions<I, O> = {
    method: "POST" | "PUT" | "PATCH" | "DELETE";
};

export function usePostFile<I, O>(
    url: string,
    options: UsePostOptions<I, O> = { method: "POST" },
    file: File | any
) {
    const [data, setData] = useState<O | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    url = process.env.REACT_APP_API_URL + url;

    const run = useCallback(
        async (data: I) => {
            try {
                setIsLoading(true);
                let formData = new FormData();
                formData.append("file", file);
                let response;
                if (url.toLowerCase().includes("file")) {
                    response = await fetch(url, {
                        method: options.method,
                        // headers: {
                        //     "Content-Type": "multipart/form-data",
                        // },
                        body: formData,
                    });
                } else {
                    response = await fetch(url, {
                        method: options.method,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                    });
                }

                if (!response.ok) {
                    throw new Error("Response is not ok");
                }

                const responseJson = await response.json();
                setData(responseJson);
                setIsLoading(false);
                return responseJson;
            } catch (error: any) {
                setError(error && error.message);
            }
        },
        [url, file]
    );

    return { run, data, error, isLoading };
}
