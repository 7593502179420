import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, FormControl, useToast } from "@chakra-ui/react";
import { useMojoEffect } from "api/useMojoEffect";
import { FloatingLabelInput } from "components/FloatingLabelInput";
import { Toggle } from "components/Toggle";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export type AddEditContactFormProps = {
    vendorId: string;
    refetch: () => void;
};

export default function AddEditContactForm({
    vendorId,
    refetch,
}: AddEditContactFormProps) {
    const { run: runCreate } = useMojoEffect("/api/v1/VendorContacts/create", "post");
    const [isRunningCreate, setCreate] = useState(false);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
        setValue,
    } = useForm();

    const toast = useToast();

    const setIsFormDirty = (dirty:boolean) => {

    }

     return (
        <form
            onSubmit={handleSubmit(async (data) => {
               setCreate(true);
                const [createdData, error] = await runCreate({
                    ...data,
                    VendorId: vendorId,
                    // activeFlag: true,
                    status: data.status ? 1 : 0,
                });
                if (error===null) {
                    await refetch();
                    reset();
                    toast({
                        title: "Contact created",
                        status: "success",
                    });
                } else {
                    toast({
                        title: "Contact creation failed",
                        status: "error",
                    });
                }
                setCreate(false);
                return createdData;
            })}
        >
            <FormControl isInvalid={!!errors.email}>
                <div className="form-upper-wrapper">
                    <div className="form-upper form">
                        <div className="form-upper-title">ADD NEW CONTACT</div>
                    </div>

                    <div className="form">
                        <div className="input-group">
                            <FloatingLabelInput
                                placeholder="First Name"
                                {...register("firstName")}
                            />
                        </div>
                        <div className="input-group">
                            <FloatingLabelInput
                                placeholder="Last Name"
                                {...register("lastName")}
                            />
                        </div>
                        <div className="input-group">
                            <FloatingLabelInput
                                placeholder="E-mail"
                                {...register("email")}
                            />
                        </div>
                        <div className="input-group">
                            <FloatingLabelInput
                                placeholder="Phone"
                                {...register("phone")}
                            />
                        </div>
                        <div className="input-group min-width-20">
                            <FloatingLabelInput
                                placeholder="Title"
                                {...register("title")}
                            />
                        </div>
                        <Box alignSelf="center">
                            <Toggle
                             control={control}
                                name="status"
                                label="Active?"
                                setIsFormDirty = {setIsFormDirty}
                            />
                        </Box>
                        <div className="bt-add">
                            <Button
                                isLoading={isRunningCreate}
                                variant="mojoPrimary"
                                type="submit"
                            >
                                <AddIcon />
                            </Button>
                        </div>
                    </div>
                </div>
            </FormControl>
        </form>
    );
}
