import { useParams } from "react-router-dom";
import { useMojoEffect } from "api/useMojoEffect";
import { useMojoFetch } from "api/useMojoFetch";
import { useMemo } from "react";
import { useToast } from "@chakra-ui/react";
import { GoalsForm, GoalsFormProps } from "../GoalsForm";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { useNavigate } from "hooks/useNavigate";

export function AddEditGoalsForm({ onlyRequired }: { onlyRequired?: boolean }){
    const {  goalId } = useParams();

    const navigate = useNavigate();

    function GoalEditForm({ goalId }){
    
        const id = goalId;
        const {
            run: runUpdate,           
        } = useMojoEffect(`/api/v1/Goals/${id}`, 'patch');
    
        const {
            data: goalData,
            error: vendorError,
            isLoading,
        } = useMojoFetch(`/api/v1/Goals/${id}`, 'get');
    
        const toast = useToast();        
        const values = goalData as GoalsFormProps['values'];
       
        return (
            
            <GoalsForm
                isLoading={false}
                //isSubmitting={isRunningUpdate}
                isNew = {false}
                
                submit={async (args) => {
                    args.goalLeads = parseFloat(args.goalLeads.toString());
                    args.goalSales = parseFloat(args.goalSales.toString());
                    args.desiredClosingRelation = parseFloat(args.desiredClosingRelation.toString()); 
                   const [updatedData, error] = await runUpdate({
                        ...args,                        
                    });

                    if (error === null) {                        
                        toast({
                            title: 'Goal updated',
                            status: 'success',                                                      
                        });                        
                    } else {
                        toast({
                            title: 'Goal update failed',
                            status: 'error',                            
                        }

                        );
                    
                    }
                    //return res;
                }}
                values={values}
                defaultValues={values}
                btnTitle="UPDATE"
                btnIcon={<EditIcon />}
                formTitle={`UPDATE ${goalData?.ClientId}`}   
                />
        )
    }    
    
    function GoalAddForm({ onlyRequired }: { onlyRequired?: boolean }){
        const {
            run: runCreate
        } = useMojoEffect('/api/v1/Goals/create', 'post');
    
        const toast = useToast();
        return (
            <GoalsForm
                isLoading={false}
                //isSubmitting={isRunningCreate}
                isNew = {true}
                submit={async (args) => {   
                    args.goalLeads = parseFloat(args.goalLeads.toString());
                    args.goalSales = parseFloat(args.goalSales.toString());
                    args.desiredClosingRelation = parseFloat(args.desiredClosingRelation.toString());              ;
                    const [createdGoal, error] = await runCreate({
                        ...args
                    });
                    if (error === null) {
                        toast({
                            title: 'Goal created',
                            status: 'success',                            
                        });
                        //setTimeout(() => {navigate('/goals/'+ res.GoalId), 5000});
                        navigate('/goals/'+ createdGoal.GoalId);
                    } else {
                        
                        toast({
                            title: 'Goal create failed',
                            status: 'error',
                        });
                        
                    }
                    //return res;
                }}
                btnTitle="ADD GOAL"
                btnIcon={<EditIcon />}
                formTitle={`CREATE NEW GOAL`}   
                />
        )
    }
    
    const form = useMemo(() => {
        if (goalId) {           
            return (               
               <GoalEditForm goalId={goalId} />
            );
        }
        return (
            <GoalAddForm onlyRequired={onlyRequired} />
        );
    }, [GoalAddForm, goalId, onlyRequired]);
    return form;
 
}