import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, FormControl, useToast } from "@chakra-ui/react";
import { useMojoEffect } from "api/useMojoEffect";
import { FloatingLabelInput } from "components/FloatingLabelInput";
import { Select, useSelect } from "components/Select";
import { Toggle } from "components/Toggle";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./AddEditServicesForm.scss";

export type AddEditServicesFormProps = {
    VendorId: string;
    refetch: () => void;
};

export function AddEditServicesForm({
    VendorId,
    refetch,
}: AddEditServicesFormProps) {
    const { run: runCreate } = useMojoEffect("/api/v1/Services/create", "post");

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm();

    //const values = {} as Parameters<typeof runCreate>[0];
    const toast = useToast();

    return (
        <form
            onSubmit={handleSubmit(async (data) => {
                const [createdData, error] = await runCreate({
                    ...data,                 
                });
                if (error === null) {
                    await refetch();
                    reset();
                    toast({
                        title: "Vendor service created",
                        status: "success",
                    });
                } else {
                    toast({
                        title: "Vendor service creation failed",
                        status: "error",
                    });
                }
                return createdData;
            })}
        >
            <FormControl
                // isInvalid={!!errors.username}
            >
                <div className="add-edit-services-form form-upper-wrapper">
                    <div className="form-upper form">
                        <div className="form-upper-title">ADD NEW SERVICE</div>
                    </div>
                    <div className="form services-form">
                        <div className="input-group">
                            <FloatingLabelInput
                                placeholder="Service Name"
                                {...register("name")}
                            />
                        </div>
                        <div className="bt-add">
                            <Button
                                //isLoading={isRunningCreate}
                                variant="mojoPrimary"
                                type="submit"
                            >
                                <AddIcon />
                            </Button>
                        </div>
                    </div>
                </div>
            </FormControl>
        </form>
    );
}
