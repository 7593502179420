import { useParams } from "react-router-dom";
import { TabItemEnum } from "./TabItemEnum";
import { useNavigate } from "hooks/useNavigate";
import { useEffect } from "react";

export type TabsProps = {
    currentTab: TabItemEnum;
  }

export default function Tabs({ currentTab }: TabsProps) {
    const { partnerId } = useParams();
    
    const getTabClass = (tab: TabItemEnum) => {
        return tab === currentTab ? 'item-menu-active' : 'item-menu';
    }

    const getTabItemClass = (tab: TabItemEnum) => {
        return tab === currentTab ? 'menu-label-active' : 'menu-label gothamhtf-book-normal-gray-16px';
    }

    const navigate = useNavigate();

    useEffect(()=>{
        navigate(`/partners/${partnerId}/${currentTab}`)
    },[])

    return (
        <div className="submenu-wrapper submenu">
            <div className="submenu-container submenu">
                <div className={getTabClass(TabItemEnum.Notes)}
                     onClick={() => navigate(`/partners/${partnerId}/notes`)}
                >
                    <div className={getTabItemClass(TabItemEnum.Notes)}>
                        NOTES
                    </div>
                </div>
                <div className={getTabClass(TabItemEnum.Presentations)}
                     onClick={() => navigate(`/partners/${partnerId}/presentations`)}
                >
                    <div className={getTabItemClass(TabItemEnum.Presentations)}>
                        PRESENTATION FILES
                    </div>
                </div>
                <div className={getTabClass(TabItemEnum.Services)}
                     onClick={() => navigate(`/partners/${partnerId}/services`)}
                >
                    <div className={getTabItemClass(TabItemEnum.Services)}>
                        SERVICES
                    </div>
                </div>
                <div className={getTabClass(TabItemEnum.Links)}
                     onClick={() => navigate(`/partners/${partnerId}/links`)}
                >
                    <div className={getTabItemClass(TabItemEnum.Links)}>
                        OTHER LINKS
                    </div>
                </div>
                <div className={getTabClass(TabItemEnum.Contacts)}
                     onClick={() => navigate(`/partners/${partnerId}/contacts`)}
                >
                    <div className={getTabItemClass(TabItemEnum.Contacts)}>
                        PARTNER CONTACTS
                    </div>
                </div>
            </div>
        </div>
    );
}