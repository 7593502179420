import { useCallback } from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  Column,
} from "react-table";
import { EmptyDataTable } from "./EmptyDataTable";
import { matchSorterFn } from "./Utilities/Sorting";
import {
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  AccordionItem,
  Accordion,
  Spacer,
  useColorModeValue,
  Select,
  Input,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Box,
  Tbody,
  Table,
  Thead,
  WrapItem,
  Wrap,
  Th,
  Tr,
  Td,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import "./Styles/datatable.css";
import "./Styles/styleguide.css";
import "./Styles/controls.css";
import "./DataTable.scss";

function getNavigationButtonClass(canPreviousPage) {
  return canPreviousPage
    ? "bt-pagination-navigation"
    : "bt-pagination-navigation disabled";
}

export type DataTableProps<T extends object> = {
  children?: {
    tableHeader?: any;
    rowFooter?: any;
  };
  defaultColumn?: any;
  customFunctions?: any;
  data: T[];
  columns: (Column<T> & {
    label?: (t: T) => string;
    disabled?: boolean;
    masked?: boolean;
  })[];
  noControl?: boolean;
  noFilter?: boolean;
  cells?: { [key: string]: (props: any) => JSX.Element };
  cellType?: (rowIdx: number, cellIdx: number) => string;
  chooseRows?: boolean;
  showFilter?: boolean;
  defaultShownColumns?: any;
};

export function DataTable<T extends object>({
  cells,
  cellType,
  children: { tableHeader, rowFooter } = {},
  chooseRows,
  columns,
  customFunctions,
  data,
  defaultColumn,
  noControl,
  showFilter,
  defaultShownColumns,
}: DataTableProps<T>) {
  const filterTypes = { rankedMatchSorter: matchSorterFn };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    //pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    visibleColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: "", ...(defaultColumn || {}), ...(cells || {}) },
      filterTypes,
      initialState: {
        hiddenColumns: [],
      },
      ...(customFunctions || {}),
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const bg = useColorModeValue("white.100", "black.100");
  const color = useColorModeValue("black.200", "white.100");

  const selectedColumns = columns
    .filter((c) =>
      visibleColumns.some(
        (vc) => vc.Header?.toString() === c.Header?.toString()
      )
    )
    .map((c) => c.Header?.toString() || "");

  /*
Broken function
*/
  const onSelectedColumnsChange = useCallback(
    (selectedColumns) => {
      const myHiddenColumns = columns
        .filter(
          (c) => !selectedColumns.some((vc) => vc === c.Header?.toString())
        )
        .map((c) => c.accessor?.toString() || "");
      if (myHiddenColumns.length <= columns.length - 1) {
        setHiddenColumns(myHiddenColumns);
      }
    },
    [columns, setHiddenColumns]
  );

  const tbodyMarkup = page.length ? (
    page.map((row, rowIdx) => {
      prepareRow(row);
      const rowMarkup = row.cells.map((cell, cellIdx) => (
        <Td {...cell.getCellProps()}>
          {cell.render(cellType ? cellType(row.index, cellIdx) : "Cell")}
        </Td>
      ));
      const rowFooterMarkup = rowFooter ? (
        <td>{rowFooter(row.original, row.index)}</td>
      ) : (
        <></>
      );
      return (
        <Tr {...row.getRowProps()}>
          {rowMarkup}
          {rowFooterMarkup}
        </Tr>
      );
    })
  ) : (
    <EmptyDataTable columns={columns} />
  );

  const tableControlsMarkup = (
    <Menu variant="primary" closeOnSelect={false}>
      <MenuButton className="data-table-show-hide-columns-toggle-button">
        Show/Hide Columns
        <ChevronDownIcon />
      </MenuButton>
      <MenuList className="data-table-show-hide-columns-dropdown">
        <MenuOptionGroup
          type="checkbox"
          defaultValue={selectedColumns}
          onChange={onSelectedColumnsChange}
        >
          {columns.map((column, i) => (
            <MenuItemOption key={i} value={column.Header?.toString()}>
              {column.Header?.toString()}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );

  const filterMarkup = (
    <Wrap>
      {headerGroups[0].headers.map((column, idx) => {
        if (column.Filter) {
          return (
            <WrapItem key={idx}>
              <Box w={"200px"}>{column.render("Filter")}</Box>
            </WrapItem>
          );
        }
      })}
    </Wrap>
  );

  return (
    <>
      {showFilter && (<Flex justifyContent="space-between" alignItems="center">
        <Accordion defaultIndex={[0]} allowMultiple w={"80%"}>
          <AccordionItem>
            <AccordionButton >
              <Flex>Search & Filter</Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} >
              {filterMarkup}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Box>{chooseRows && tableControlsMarkup}</Box>
      </Flex>)}

      <Flex color={color} borderRadius={"lg"} w="100%" overflowY="auto">
        <Table className="datatable" { ...getTableProps() } bg={bg} color={color}>
          <Thead color={color}>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <Flex className="sorting-wrapper">
                      <Box>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img
                              src="/img/icon-arrow-down.png"
                              className="order-icon"
                              alt="Order"
                            />
                          ) : (
                            <img
                              src="/img/icon-arrow-up.png"
                              className="order-icon"
                              alt="Order"
                            />
                          )
                        ) : (
                          <img
                            src="/img/icon-arrow-up.png"
                            className="order-icon"
                            alt="Order"
                          />
                        )}
                      </Box>
                      <Box>{column.render("Header")}</Box>
                    </Flex>
                  </Th>
                ))}
                {rowFooter && <th>ACTIONS</th>}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>{tbodyMarkup}</Tbody>
        </Table>
      </Flex>
      {!noControl && (
        <Box w="100%">
          <Flex>
            <Box>
              <Select
                sx={{ fontSize: "1em", width: "8em" }}
                value={pageSize.toString()}
                bg={bg}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Select>
            </Box>
            <Spacer />
            <Box>
              <Flex className="pagination-wrapper">
                <Box className="go-to-page">
                  <Input
                    type="number"
                    w={20}
                    placeholder="Page"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                  />
                </Box>
                <Box
                  className={getNavigationButtonClass(canPreviousPage)}
                  onClick={() => previousPage()}
                >
                  Prev
                </Box>
                <Box className="navbar">
                  <Box className="navbar-link-bt-pagination-current">
                    {pageIndex + 1} of {pageOptions.length}
                  </Box>
                </Box>
                <Box
                  className={getNavigationButtonClass(canNextPage)}
                  onClick={() => nextPage()}
                >
                  Next
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
}
