import { useToast, Box, Center, Flex, Image } from '@chakra-ui/react';
import { useMojoFetch } from 'api/useMojoFetch';
import { useMojoEffect } from 'api/useMojoEffect';
import { BudgetListDataTable } from '../BudgetListDataTable';
import { useCallback } from 'react';
import logo from '../../../logo-teal.png';
import './BudgetsList.scss';

export function BudgetsList() {
  //const { selectedGroupId, selectedTenantId } = useAppSelector(
 //   (state: RootState) => state.app
 // );
  const { error, data: budgets, refetch, isLoading } = useMojoFetch('/api/v1/Budgets/all', 'get');
  const { run: runDelete } = useMojoEffect(
    '/api/v1/Budgets/',
    'delete'
  );
  const toast = useToast();

  const deleteBudget = useCallback(
    async (budgetId: string) => {
      const [opResult, error] = await runDelete(budgetId);
      if (error === null) {
        toast({
          title: 'Budget deleted',
          status: 'success',
        });
        await refetch();
      } else {
        toast({
          title: 'Failed to delete budget',
          status: 'error',
        });
      }
    },
    [refetch, runDelete, toast]
  );

  if (isLoading) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }
  
  if (error.message !== '') {         
    return <Center>{error.message}</Center>;
  }

  return (
    <Box className='budget-list'>
      <BudgetListDataTable
        data={budgets}
        error={error}
        deleteBudget={deleteBudget}
      />
    </Box>
  );
}
