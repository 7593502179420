import AccordionComponent from "components/Accordion/AccordionComponent";
import BudgetTable from "../BudgetTable/BudgetTable";
import { useMojoEffect } from 'api/useMojoEffect';
import { useEffect, useState } from 'react';

function BudgetsAccordion({ year, location_id }) {
    const [data, setData] = useState([]);

    const { runWithId } = useMojoEffect(`/api/v1/budgets`, 'get');

    useEffect(() => {
        const myFunc = async () => {
        const [myData, _] = await runWithId(
            `?tenantId=${location_id}&year=${year}`
        );
        setData(myData);
        };
        myFunc();
    }, [location_id, year]);

    return (
        <AccordionComponent title={year + ' Budget'}>
            <BudgetTable data={data}/>
        </AccordionComponent>
    );
}

export default BudgetsAccordion;